.scale-prices {
    margin-left: -10px;
    margin-right: -10px;
}

.scale-prices__table {
    width: 100%;
    font-size: 13px;
}

.scale-prices th,
.scale-prices td {
    font-weight: normal;
    padding: 4px 10px;
}

.scale-prices tr.active td,
.scale-prices tr.active th {
    font-weight: bold;
    background: #e2e2e8;
}