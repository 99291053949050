.parsley-success,
.parsley-error,
.has-error,
.has-success {
    transition: all .2s ease;
}

input.parsley-success,
select.parsley-success,
textarea.parsley-success,
.has-success input,
.has-success select,
.parsley-success textarea{
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6;
}

input.parsley-error,
select.parsley-error,
textarea.parsley-error,
.has-error input,
.has-error select,
.has-error textarea{
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}

.parsley-errors-list {
  color: #B94A48;
  margin: 8px 0 3px;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 1.5em;
  opacity: 0;
  transition: all .3s ease-in;
}

.form-group--white-errors .parsley-errors-list {
  color: white;
}

.parsley-errors-list.filled {
  opacity: 1;
}

.checkbox-wrap.parsley-error label,
.has-error .checkbox-wrap label {
  color: #b94a48;
}

.checkbox-wrap.parsley-error label:before,
.has-error .checkbox-wrap label:before{
  color: #b94a48;
  background-color: #f2dede;
  border: 1px solid #eed3d7;
}