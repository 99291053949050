.headline-block {
    margin: 14px 0 25px;
    position: relative;
    padding-left: 38px;
}
@media screen and (min-width: 768px) {
    .headline-block {
        margin: 28px 0 35px;
        display: inline-block;
        padding-left: 75px;
    }
}
.headline-block__icon {
    position: absolute;
    left: 5px;
    top: -2px;
    font-size: 28px;
}
@media screen and (min-width: 768px) {
    .headline-block__icon {
        left: 0;
        top: 15px;
        font-size: 52px;
    }
}
.headline-block__hl {
    margin-bottom:8px;
}