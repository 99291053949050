.delete-from-cart-checkbox {
    display:none;
}

.cart-location-choose-wrapper #search-locations {
    height: 50px;
}

.cart-location-choose-wrapper .form-location-search button, .form-location-search a.btn {
    height: 50px;
    line-height: 50px;
}

.special-price__old-price{
    text-decoration: line-through;
    font-size: 13px;
}

.special-price__text{
    font-size: 15px;
}

@media screen and (min-width: 768px){
    .special-price__old-price{
        font-size: 16px;
    }

    .special-price__text{
        font-size: 17px;
    }
}


/*---------------- FAQ Accordion --------------*/
.cart-accordion .panel{
    margin-bottom: 0;
}
.cart-accordion .panel-default--border .panel-title a {
    font-size: 13px;
}
.cart-accordion .panel-title__icon-right {
    font-size: 7px;
}
.cart-accordion__title {
    color: #118516;
}
@media screen and (min-width: 400px) and (max-width: 767px) {
    .cart-accordion .panel-default--border .panel-title a {
        font-size: 15px;
    }
}
@media screen and (max-width: 767px) {
    .cart-accordion .panel-default--border .panel-title a {
        padding: 8px 14px;
    }
}
@media screen and (min-width: 768px) {
    .cart-accordion .panel{
        margin-bottom: 4px;
    }
    .cart-accordion .panel-default--border .panel-title a {
        font-size: 17px;
    }
    .cart-accordion .panel-title__icon-right {
        font-size: 8px;
    }
    .cart-accordion__title {
        margin-bottom: 10px;
    }
    .cart-accordion__container {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.color-indicator-icon.color-green {
    color: #118516 !important;
}
.color-indicator-icon.color-orange {
    color: #e49439 !important;
}
.color-indicator-icon.color-red {
    color: #ff0000 !important;
}