.pimcore-pdfFullscreenClose:after {
    position: absolute;
    color: #118516;
    content: "\E00C";
    font-family: iconfont;
    font-size: 30px;
}

.pimcore-pdfFullscreenClose {
    font-size: 0 !important;
    background: transparent !important;
    opacity: 1 !important;
}

.pimcore-pdfDownload:after {
    position: absolute;
    color: #118516;
    content: "\E001";
    font-family: iconfont;
    font-size: 30px;
}

.pimcore-pdfDownload {
    font-size: 0 !important;
    background: transparent !important;
    opacity: 1 !important;
}