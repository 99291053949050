/* news list */
.news-item {
    background: #e6e6ec;
}

.news-item--date {
    font-size: 13px;
    position: absolute;
    bottom: 0;
    background: var(--color-green);
    padding: 8px 18px 8px 18px;
    color: white;
    font-weight: 700;
    line-height: 15px;
}

.news-item--headline {
    margin-left: 10px;
    font-size: 18px;
    color: var(--color-green);
    line-height: 22px;
    margin-top: 10px;
    padding-bottom: 10px;
}

.news-item--triangle{
    width: 33px;
    height: 33px;
    background: linear-gradient(135deg,rgba(92,172,230,0) 0,rgba(68,78,82,0) 50%,#cfcfd9 51%,#cfcfd9 100%);
    right: 0;
    bottom: 0;
    position: absolute;
}


@media screen and (max-width: 767px) {
    .activity-item{
        margin: 0 0 10px 0;
    }

    .news-item{
        margin: 0 0 10px 0;
    }
    .news-header--dateseparator{
        margin: 40px 0;
    }
    .news-item--triangle {
        right: 0;
        bottom: 0;
    }
}

/*news detail*/
.news-header {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
}

.news-header > .col-has-text {
    padding: 10px;
}

.news-header > .col-has-text > p{
    padding: 10px;
}

.news-header > .col-has-image {
    padding-right: 0;
}

.news-header__date{

}

.news-header__h1{
    margin-top: 15px !important;
}

.news-header--dateseparator{
    border-top: 1px solid #efefef;
    margin: 50px 0;
    position: relative;
}

.news-header--dateseparator i{
    position: absolute;
    left: 50%;
    font-size: 30px;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 0 25px;
    color: #585d5f;
}

.news-header--dateseparator__date{
    color: #585d5f;
    font-size: 18px;
    margin: 30px 0;
    font-family: var(--font-headline-bold);
}
@media screen and (min-width: 768px) {
    .news-header--dateseparator__date{
        font-size: 22px;
    }
}

/*video section*/
.news-detail--video{
    position: relative;
}

.news-detail--video .vjs-default-skin.vjs-default-skin .vjs-big-play-button{
    margin-top: -30px;
    margin-left: -30px;
    width: 60px;
    height: 60px;
}

.news-detail--video .vjs-default-skin .vjs-big-play-button.vjs-big-play-button:before{
    left: 3px;
    line-height:60px;
    font-size: 24px;
}

/*flugblatt*/
.flugblatt-item h3{
    /* Text style for "Technik &" */
    color: var(--color-green);
    font-size: 18px;
    line-height: 22px;
}

.flugblatt-item__text{
    min-height: 50px;
}




/*img teaser*/
.img-teaser {
    background: #e6e6ec;
    position: relative;
    display: block;
}
.img-teaser:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-width: 0 0 33px 33px;
    border-color: transparent transparent #d0d0da transparent;
}
.img-teaser__body {
    padding: 15px 15px 10px;
    min-height: 65px;
}
.img-teaser--lg .img-teaser__body {
    padding: 15px 20px 20px;
    font-size: 14px;
}
.img-teaser__body__hl {
    font-size: 18px;
    line-height:calc(22/18);
    color: var(--color-green);
    margin-left: 0;
}
.img-teaser__head {
    position: relative;
}
.img-teaser__head__badge {
    background: var(--color-green);
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    padding: 6px 15px 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}