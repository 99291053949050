.checkout-steps {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
    .checkout-steps {
        margin-top: 40px;
        margin-bottom: 100px;
        height: 92px;
    }
    .checkout-steps:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -11px;
        left: 0;
        right: 0;
        height: 1px;
        background: #e2e2e8;
    }
}
.checkout-steps__item, .checkout-steps__finish {
    font-family: var(--font-default);
    font-weight: 700;
    font-size: 13px;
    line-height:calc(15/13);
}
.checkout-steps .checkout-steps__item>a {
    padding: 0;
}
.checkout-steps__item__count {
    width: 67px;
    height: 67px;
    border-radius: 100%;
    font-size: 38px;
    line-height:calc(46/38);
    display: block;
    font-family:var(--font-headline-bold);
    font-weight: 400;
    padding: 11px;
    background: #ebebef;
    margin: 0 auto 10px;
}
.checkout-steps__item {
    color: var(--color-green);
}
.checkout-steps__item.active {
    color: #585d5f;
}
.checkout-steps__item.active .checkout-steps__item__count {
    background: #fff028;
}
.sidebar-checkout__title {
    padding: 15px 15px 0;
    font-size: 18px;
    font-family:var(--font-headline-bold);
    font-weight: 400;
}
@media screen and (min-width: 768px) {
    .sidebar-checkout__title {
        font-size: 20px;
        padding: 25px 30px 0;
    }
}

.checkout-steps__finish {
    float: right;
    text-align: center;
    padding-top: 6px;
}
.checkout-steps__finish__icon {
    font-size: 54px;
    color: #d0d0da;
    display: block;
    background: #fff;
    margin-bottom: 17px;
}
.checkout-steps__btn {
    background: var(--color-bg-primary);
    height: 62px;
    padding: 26px 0;
    display: inline-block;
    width: 47px;
    text-align: center;
    font-size: 10px;
}
.checkout-steps__count {
    font-family:var(--font-headline-bold);
    font-weight: 400;
    font-size: 20px;
    line-height:1.2;
    color: var(--color-green);
}

@media screen and (max-width: 768px) {
    .checkout--reservation nav ul li{
        width: 33%;
        float: left;
    }
    .checkout--reservation nav ul li .step-nr{
        width: 57px;
        height: 57px;
        font-size: 30px;
    }
    .checkout--reservation .checkout-steps{
        margin-bottom: 0;
    }
    .checkout--reservation .checkout-steps:before{
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -22px;
        left: 0;
        right: 0;
        height: 1px;
        background: #e2e2e8;
    }
}