.row--gutter-width-2 .gall-item {
    margin-top: 2px;
    margin-bottom: 2px;
}

.gall-item__toggler {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    background: #2b3133;
    color: #fff;
    text-align: center;
    justify-content: center;
}

/*
@media screen and (min-width: 768px) {

    .gall-item {
        margin-top: 10px;
        margin-bottom: 10px;
    }

}*/
