@media screen and (max-width: 767px){
    .content-section--table.wysiwyg {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}

.table,
.table .table,
.wysiwyg table {
    width: 100%;
    max-width: 100%;
    margin: 0;
    background-color: transparent;
}
.wysiwyg table {
    margin: 25px 0;
}
.table th,
.table td,
.wysiwyg table th,
.wysiwyg table td {
    padding: 6px;
    line-height: 1.42857143;
    vertical-align: top;
}
.table th.text-left,
.table td.text-left,
.wysiwyg table th.text-left,
.wysiwyg table td.text-left,
.table-text-left th,
.table-text-left td,
.wysiwyg .table-text-left th,
.wysiwyg .table-text-left td {
    text-align:left;
}
.table th small,
.table td small,
.wysiwyg table th small,
.wysiwyg table td small {
    font-size:75%;
}

.table thead th,
.table thead th.thead,
.wysiwyg table thead th {
    background: var(--color-green);
    color: #ffffff;
}

.table thead th.thead {
    border:0;
}

.table > tbody > tr > td,
.wysiwyg table > tbody > tr > td,
.table > tbody > tr > th,
.wysiwyg table > tbody > tr > th,
.table > tfoot > tr > td,
.wysiwyg table > tfoot > tr > td,
.table > tfoot > tr > th,
.wysiwyg table > tfoot > tr > th,
.table > thead > tr > td,
.wysiwyg table > thead > tr > td,
.table > thead > tr > th,
.wysiwyg table > thead > tr > th {

}
@media screen and (min-width: 768px) {
    .table > tbody > tr > td,
    .wysiwyg table > tbody > tr > td,
    .table > tbody > tr > th,
    .wysiwyg table > tbody > tr > th,
    .table > tfoot > tr > td,
    .wysiwyg table > tfoot > tr > td,
    .table > tfoot > tr > th,
    .wysiwyg table > tfoot > tr > th,
    .table > thead > tr > td,
    .wysiwyg table > thead > tr > td,
    .table > thead > tr > th,
    .wysiwyg table > thead > tr > th {
        padding: 10px 15px;
    }
}
@media screen and (max-width: 767px) {
    .table > thead > tr > th,
    .wysiwyg table > tbody > tr > th {
        padding: 8px 4px;
        white-space: nowrap;
    }
}
.wysiwyg table > tbody > tr > th {
    font-width: bold;
}
.table > caption + thead > tr:first-child > td,
.wysiwyg table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.wysiwyg table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.wysiwyg table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.wysiwyg table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.wysiwyg table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.wysiwyg table > thead:first-child > tr:first-child > th {
    border-top: 0;
}

.table > tbody > tr > td,
.wysiwyg table > tbody > tr > td,
.table > tbody > tr > th,
.wysiwyg table > tbody > tr > th{
    /*border-bottom: 1px solid #646464;
    border-bottom: 1px solid rgba(100,100,100,0.6);*/
    border-top: 0;
    vertical-align: middle;
}
.table > tbody > tr > td:first-child,
.wysiwyg table > tbody > tr > td:first-child,
.table > tbody > tr > th:first-child,
.wysiwyg table > tbody > tr > th:first-child {
    border-left: none;
}


.wysiwyg table > tbody > tr:nth-of-type(odd) {
    background: var(--color-bg-primary);
}

/* hover-table-stuff */
html.desktop .wysiwyg table tr:hover td {
    -webkit-transition: background 0.15s linear;
    -moz-transition: background 0.15s linear;
    -ms-transition: background 0.15s linear;
    -o-transition: background 0.15s linear;
    transition: background 0.15s linear;
    background: rgba(17,133,22,0.20);
}
html.desktop .wysiwyg table tr td {
    -webkit-transition: background 0.25s linear;
    -moz-transition: background 0.25s linear;
    -ms-transition: background 0.25s linear;
    -o-transition: background 0.25s linear;
    transition: background 0.25s linear;
}

/* content-block--table  */
.content-block--table.wysiwyg table {
    margin-top: 0;
    margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
    .content-block--table .table > tbody > tr > td,
    .content-block--table .wysiwyg table > tbody > tr > td {
        white-space: nowrap;
    }
}


.table--product-data tr>th{
    border-right: 1px solid #fff;
    font-weight: normal;
}

.table-striped.table--product-data>tbody>tr:nth-of-type(odd){
    background-color: #f5f5fa;
}