.fuel-ajax-link .step-name {
    font-weight: 700;
}

.fuel-step-header-link {
    border: 1px solid #169e29;
    height: 40px;
    color: #118516;
    line-height: 35px;
}
.fuel-step-header-link .icon {
    line-height: 35px;
}

.fuel-step-header-link:last-child .icon {
    visibility: hidden;
}

.fuel-step-header-link.step-active {
    color: #fff;
    background: #169e29;
    background: linear-gradient(to bottom,#169e29 0,#118617 100%);
}

.is-fuel-order-search {
    height: 40px !important;
}

.fuel-order-selected-store {
    border: 1px solid red;
}

.fuel-order-location-result {
    margin: 20px;
}

.unit-placeholder {
    color: #a5a5a5;
    position: absolute;
    top: 35px;
    right: 50px;
}

.input-wrapper {
    position: relative;
}

.fuel-go-back {
    margin-top: 40px;
    border-top: 4px solid #f5f5fa;
}

.fuel-item {
    font-size: 14px;
    margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
    .fuel-item {
        font-size: 18px;
    }
}
.fuel-item + .fuel-item {
    border-top: 1px solid #e2e2e8;
    padding-top: 45px;
    margin-top: 45px;
}
.fuel-item__hl {
    color: var(--color-dark-grey);
    font-size: 26px;
    line-height:calc(45/38);
    border-bottom:1px solid #E2E2E8;
    padding-bottom: 8px;
    margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
    .fuel-item__hl {
        font-size: 38px;
        padding-bottom: 20px;
        margin-bottom: 30px;
    }
    .fuel-item__form .form-group + .form-group {
        margin-left: 45px;
    }
    .fuel-item__form.fuel-item__form .checkbox label {
        padding-left: 15px;
    }
}
.fuel-item__form .form-group, .fuel-item__form label {
    font-weight: 400;
    font-family:var(--font-headline-default);
}
.fuel-item__form__control {
    max-width: 85px;
    margin: 0 10px;
}
.fuel-item__form__control--sm {
    max-width: 65px;
    margin: 0 10px;
}
@media screen and (max-width: 767px) {
    .form-inline .form-group {
        vertical-align: middle;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
}

.fuel-item__price {
    font-size: 14px;
    margin-top: 20px;
}
.fuel-item__price__row + .fuel-item__price__row {
    margin-top: 6px;
}
@media screen and (min-width: 768px) {
    .fuel-item__price {
        font-size: 15px;
        margin-top: 30px;
    }
    .fuel-item__price__row + .fuel-item__price__row {
        margin-top: 10px;
    }
}
.fuel-item__price__row.fuel-item__price__row--total {
    font-size: 16px;
    border-top: 1px solid #e2e2e8;
    margin-top: 10px;
    padding-top: 6px;
}
@media screen and (min-width: 768px) {
    .fuel-item__price__row.fuel-item__price__row--total {
        font-size: 18px;
        margin-top: 15px;
        padding-top: 12px;
        margin-bottom: 5px;
    }
}


/* fuel-box */
.fuel-box {
    cursor: pointer;
    font-size:15px;
    font-family: 'interstate_regular', serif;
    background:#F7F7F7;
}

@media screen and (min-width: 768px) {
    .fuel-box {
        opacity: .8;
        border:3px solid transparent;
    }
    .fuel-box:hover {
        opacity: 1;
    }
    .fuel-box--active {
        opacity: 1;
        border:3px solid var(--color-green);
    }
}
.fuel-box__hl {
    text-align: center;
    padding:20px 26px;
    font-size: 28px;
    line-height: 1.125;
    font-family: 'interstate_regular', serif;
    color: #ffffff;
    background-color: var(--color-green);
}
.fuel-box__body {
    padding:25px 20px 50px 20px;
}

.fuel-box__prices {
    margin:40px 0;
}
.fuel-box__prices__item {
    padding:10px;
    margin:6px 0;
    font-size: 17px;
    line-height: 1.125;
    font-family: 'interstate_regular', serif;
}
.fuel-box__prices__item--active {
    color:var(--color-green);
    background:#EBEBEB;
}
.fuel-box__prices__small {
    font-size:13px;
}

@media screen and (min-width: 1200px) {
    .fuel-box__hl {
        font-size: 34px;
    }
}

@media screen and (max-width: 767px) {
    .fuel-box {
        margin:20px 0;
        font-size:14px;
    }
    .fuel-box__hl--collapse {
        display: block;
        position: relative;
        text-align: left;
        padding:10px 40px 10px 15px;
        font-size:18px;
    }
    .fuel-box__hl-collapse-toggler {
        font-size: 12px;
        position: absolute;
        right:15px;
        top:50%;
        transform: translateY(-50%) rotate(180deg);
    }
    .collapsed .fuel-box__hl-collapse-toggler {
        transform: translateY(-50%) rotate(0deg);
    }
    .fuel-box__body {
        padding:15px 15px 25px 15px;
    }
    .fuel-box__prices {
        margin:20px 0;
    }
    .fuel-box__prices__item {
        padding:6px;
        font-size: 14px;
    }
}


/* fuel-form-box */
.fuel-form-box {
    padding:15px 15px 30px 15px;
    font-size:14px;
    font-family: 'interstate_regular', serif;
    background:#F7F7F7;
}
.fuel-form-box__form-control {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .fuel-form-box {
        padding:25px 30px 50px 30px;
        font-size:20px;
    }
    .fuel-form-box__form-control {
        width: 100%;
        text-align: center;
        height: 54px;
        padding: 16px 15px;
    }
}



.fuel-box__total {
    font-size: 16px;
    line-height: 1.125;
    font-family: 'interstate_regular', serif;
}
.fuel-box__total .row + .row.row {
    margin-top:10px;
}
.fuel-box__total__price {
    font-size: 20px;
    margin-top:10px;
    padding-top:10px;
    border-top: 1px solid #e2e2e8;
}

@media screen and (max-width: 767px) {
    .fuel-box__total {
        font-size: 14px;
    }
    .fuel-box__total__price {
        font-size: 17px;
    }
}




/* fuel quickfinder */
.fuel-quickform__form-control {
    width: 100px;
    height: 44px;
    padding: 10px 15px;
}
@media screen and (min-width: 768px) {
    .fuel-quickform .form-group {
        margin-left:30px;
    }
    .fuel-quickform label{
        display: block;
        margin-bottom:10px;
    }
}
@media screen and (max-width: 767px) {
    .fuel-quickform label{
        display: inline-block;
        width: 40%;
    }
    .fuel-quickform .fuel-quickform__form-control{
        display: inline-block;
        width: 59%;
    }
}