.slider--thumbs__main {
    border: 1px solid #e2e2e8;
    padding: 10px;
    margin-bottom: 20px;
}

.slider--thumbs__thumbs {

}

@media screen and (max-width: 767px) {

    .slider--thumbs__main {
        margin-bottom: 50px;
    }
    .slider--thumbs__thumbs {
        display: none;
    }
}


@media screen and (min-width: 768px) {

    .slider--thumbs__thumbs {
        margin: 0 47px;
    }

    .slider--thumbs__thumbs .slick-slide {
        margin: 0;
        padding: 0;
        outline: none;
    }
    .slider--thumbs__thumbs .slick-slide + .slick-slide {
        cursor: pointer;
        position: relative;
    }

    .slider--thumbs__thumbs .slick-thumb-arrow {
        position: absolute;
        left: -47px;
        top: 0;
        width: 47px;
        height: 60px;
        line-height: 60px;
        background-color: #ebebef;
        color: #2b3133;
        font-size: 10px;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .slider--thumbs__thumbs .slick-thumb-arrow.slick-thumb-arrow--next {
        right: -47px;
        left: auto;
    }

}

@media screen and (min-width: 992px){
    .slider--thumbs__thumbs .slick-slide + .slick-slide:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #dcdedf;
    }

}

@media screen and (min-width: 1200px) {
    .slider--thumbs__main {
        padding: 15px;
    }
}


@media screen and (max-width: 767px){
    .slick-thumb-arrow {
        position: absolute;
        bottom: -32px;
        padding: 5px 10px;
        z-index: 2;
    }

    .slick-thumb-arrow .icon{
        position: relative;
        top: 3px;
    }

    .slick-thumb-arrow.slick-thumb-arrow--prev{
        left:0;
    }

    .slick-thumb-arrow.slick-thumb-arrow--next{
        right:0;
    }
}