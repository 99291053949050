.block-price {
    font-size: 13px;
    margin-top: 30px;
}
.block-price__row {
    padding: 7px 2px 4px;
    border-bottom:1px solid #E2E2E8;
}
@media screen and (min-width: 768px) {
    .block-price__row {
        padding: 7px 20px 4px;
    }
}
.block-price__row:first-child {
    border-top:1px solid #E2E2E8;
}
.block-price__row.is-active {
    color: var(--color-green);
    font-weight: 600;
    background: #F5F5FA;
}
.block-price__row.is-active .text-grey-light {
    color: var(--color-green);
}