
@media screen and (min-width: 768px){
    .product-vehicles__item{
        padding: 30px;
        position: relative;
    }

    .product-vehicles__body{
        padding-left: 30px;
    }

    .product-vehicles__item h2{
        font-size: 24px;
    }

    .product-vehicles__desc{
        margin-top: 20px;
    }
}

.product-grid--vehicles{
    margin-bottom: 40px;
}

.product-vehicles__item:first-child{
    border-top: 1px solid #e2e2e8;
}

.search-result-wrapper .product-vehicles__item:first-child{
    border-top: none;
    padding-top: 0;
}

.product-vehicles__item{
    border-bottom: 1px solid #e2e2e8;
    padding: 15px 10px;
}

.product-vehicles__body{
    padding-left: 15px;
}

.product-vehicles__item h2{
    font-size: 13px;
    margin-bottom: 0;
}

@media screen and (max-width: 767px) {
    .search-result-wrapper .product-vehicles__body {
        padding-left: 0;
    }

    .search-result-wrapper .product-vehicles__item h2{
        font-size: 20px;
        margin-bottom: 10px;
    }
}

.product-vehicles__price{
    font-size: 30px;
}

.product-vehicles__item .info-mwst{
    color: #585d5f;
    position: relative;
    top: -4px;
}

.product-vehicles__price .price-small{
    font-size: 14px;
    vertical-align: top;
    position: relative;
    left: -7px;
}

.product-vehicle__cat{
    font-size: 13px;
}

@media screen and (min-width: 768px){
    .product-vehicles__item h2{
        font-size: 18px;
        margin-bottom: 0;
    }

    .product-vehicle__cat{
        font-size: 16px;
    }


    .product-vehicles__item .btn-details{
        position: absolute;
        right: 30px;
        bottom: 30px;
    }
}

@media screen and (max-width: 767px){
    .product-vehicles__item .btn-details{
        margin-top: 12px;
    }

    .product-vehicles__price{
        margin-top: 10px;
    }

    .product-vehicle__cat{
        margin-top: 5px;
    }

    .product-grid--vehicles{
        background: #fff;
        margin: 0 -10px;
    }

    .product-vehicles__item .info-mwst{
        margin-top: -3px;
        display: block;
    }
}