.link-green{
    color: var(--color-green);
    text-decoration: underline;
}
.align-right{
    position: absolute;
    right: 0;
    bottom: 0;
}
.hl-fz28{
    font-size: 28px;
    line-height: 30px;
}
.checkbox-center{
    left: 57%;
    transform: translate(-50%, -25%);
    position: absolute;
    top: 50%;
}
#ajax-cart-content .spinner-input{
    border: 1px solid #aaaaaa;
}

/***** TABLE CART *****/
/*hack for tables*/
td.transport-options{
    padding-right: 0px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.icon-padding{
    padding: 15px;
    padding-left: 0;
}
.price-col{
    color: var(--color-dark-grey);
    font-family: 'museo_500', sans-serif;
    font-size: 20px;
}
.whole-price-col{
    color: var(--color-dark-grey);
    font-family: 'museo_700', sans-serif;
    font-size: 20px;
}
.cart-table-wrapper .table--cart tbody tr td  {
    vertical-align: middle;
}
.cart-table-wrapper .table--cart tr td label.label--remove {
    margin: 0;
    cursor: pointer;
}

/* NUMBER SPINNtER */
.table--cart .js-number-spinner.flex-wrapper {
    justify-content: center;
}
.table--cart .js-number-spinner > button {
    background: var(--color-dark-grey);
    color: #fff;
    height: 40px;
    line-height: 1;
}

/* CART - SUMME */
.table--cart_summe {
    color: #000;
    display: block;
    padding:15px 30px;
    border-bottom: 2px solid var(--color-green);
}
.cart-headline-wrapper{
    position: relative;
    margin-bottom: 30px;
}
.cart-bottom-wrapper a.btn-go-shop{
    color: #000;
}
.cart-panel .col {
    float: left;
}
.cart-panel .col:last-child{
    border: none;
}
.cart-panel .col-33{
    width: 33.3%;
}
.cart-panel .col-desc,
.cart-panel .col-66{
    width: 66.6%;
}
.cart-panel .col-price{
    width: 10.07%;
}
.cart-panel .col-amount{
    width: 10.75%;
}
.cart-panel .col-whole-price{
    width: 12.58%;
}
.cart-panel .cart-item{
    border-bottom: 2px solid #e6e6e6;
}
.cart-panel .cart-item:last-child{
    border: none;
}
.cart-panel table > tbody > tr{
    background: var(--color-bg-primary) !important;
    border-bottom: 2px solid white;
}
.cart-panel table > thead > tr > th{
    border: none;
    background: none;
    color: var(--color-dark-grey);
}
.cart-panel table>tbody>tr>td{
    vertical-align: top;
    position: relative;
}
.cart-panel a.btn-delete{
    font-size: 18px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    color: #000;
}
.cart-panel table>tbody>tr:first-child>td a.btn-delete{
    top: 25px;
}
.cart-end-sum-wrapper .inner-row{
    padding: 12px 0;
    border-bottom: 1px solid #e6e6e6;
}
.cart-end-sum-wrapper .inner-row:first-child{
    padding-top: 0;
}
.cart-end-sum-wrapper .inner-row:last-child{
    border: none;
    padding-bottom: 0;
}
.end-price{
    color: #118516;
}
.cart-end-sum-wrapper .end-price .figure-small{
    font-size: 18px;
    vertical-align: top;
    position: relative;
}
.cart-end-sum-wrapper .end-price-desc{
    position: relative;
    top: 14px;
}
.cart-panel--bottom-wrapper{
    border-top: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary);
}
.cart-panel--bottom-wrapper>.row{
    margin: 0;
    padding-top: 25px;
}
.cart-coupon-wrapper .coupon-toggle span.icon{
    font-size: 10px;
    position: relative;
    top: 1px;
    margin-right: 5px;
}
.cart-coupon-wrapper .coupon-input{
    /*visibility: visible;*/
    margin-top: 10px;
    display: none;
}
.cart-coupon-wrapper.is-closed .coupon-input{
    /*visibility: hidden;*/
}

.cart-coupon-wrapper>a>span.icon{
    transform: rotate(0deg);
}

.cart-coupon-wrapper.is-open>a>span.icon{
    transform: rotate(180deg);
}

.cart-bottom-wrapper{
    margin-top: 15px;
}

.cart-panel .td-padding-b{
    padding-bottom: 20px;
}

.table-added-product{
    border-bottom: 2px solid #e6e6e6;
}

.cart-panel table.table-added-product>tbody>tr>td{
    padding-bottom: 15px;
}

/* ------ Media Queries ----- */

@media screen and (min-width: 768px){
    .cart-headline-wrapper a{
        position: absolute;
        right: 0;
        bottom: 14px;
    }
    .cart-bottom-wrapper{
        margin-top: 30px;
    }
    .cart-bottom-wrapper .right-wrapper{
        position: absolute;
        right: 20px;
    }
    .cart-bottom-wrapper .right-wrapper img{
        margin-right: 90px;
    }
    .cart-panel .show-xs{
        visibility: hidden;
    }
    .cart-coupon-wrapper{
        padding-left: 140px;
    }
    .cart-end-sum-wrapper .end-price{
        font-size: 56px;
        top: -8px;
    }
    .cart-end-sum-wrapper .end-price .figure-small{
        font-size: 33px;
        top: 7px;
        left: -9px;
    }
    .cart-panel table>tbody>tr.td-padding>td{
        padding-bottom: 20px;
        padding-top: 20px;
    }
}

@media screen and (max-width: 767px){
    .pt10-xs{
        padding-top: 10px;
    }
    .fz27-xs{
         font-size: 27px;
    }
    .cart-panel table{
        width: 100%;
    }
    .cart-panel table>thead {
        display: none;
    }
    .cart-panel table>tbody>tr>td {
        display: block;
        position: relative;
    }
    .cart-panel table>tbody>tr>td.align-right-xs{
        padding-left: 40%;
    }
    .cart-panel table>tbody>tr>td.not-align-right-xs{
        padding-left: 10px;
    }
    .cart-panel table>tbody>tr>td.align-more-right-xs{
        padding-left: 55%;
    }
    .cart-panel table>tbody>tr>td.filled-xs{
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .cart-panel table>tbody>tr>td.attr:before {
        content: attr(data-th);
        display: inline-block;
        text-align: left;
        position: absolute;
        left: 0;
        width: 30%;
        font-size: 15px;
        font-family: museo_700;
        color: var(--color-dark-grey);
        padding-left: 10px;
    }
    .cart-panel input.product-amount{
        left: 0;
        margin-left: 0;
    }

    .cart-panel input.product-amount{
        display: inline-block;
    }
    .cart-panel .col-66,
    .cart-panel .col-33{
        width: 100%;
    }
    .cart-panel .col-66{
        margin-bottom: 20px;
    }
    .cart-end-sum-wrapper .end-price-desc{
        top: 0;
    }
    .cart-panel--bottom-wrapper>.row {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .cart-headline-wrapper a{
        margin-left: 44px;
    }
    .warenkorb .btn.btn-arrow{
        padding: 8px 8px;
        font-size: 15px;
    }
    .warenkorb .btn.btn-go-shop.btn-arrow:after{
        border-top: 18px solid transparent;
        border-right: 15px solid;
        border-bottom: 20px solid transparent;
        left: -15px;
        border-right-color: #f5f5f5;
    }
    .warenkorb .btn.btn-ssl.btn-arrow:after{
        border-top: 18px solid transparent;
        border-left: 11px solid;
        border-bottom: 20px solid transparent;
        right: -11px;
        border-left-color: #557f0d;
    }
    .cart-bottom-wrapper .bg-info-main.btn-arrow{
        height: 38px;
        border-radius: 1px 0 0 1px;
    }
    .cart-bottom-wrapper .bg-info-main.btn-arrow:after{
        border-top: 18px solid transparent;
        border-left: 11px solid;
        border-bottom: 20px solid transparent;
        right: -12px;
        border-left-color: var(--color-primary);
    }
    .cart-bottom-wrapper .col{
        margin-bottom: 10px;
    }
    .cart-coupon-wrapper{
        border: 1px solid #e6e6e6;
        padding: 5px 10px;
        border-radius: 4px;
        cursor: pointer;
    }
    .cart-bottom-wrapper .right-wrapper{
        float: right;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .cart-end-sum-wrapper .end-price{
        font-size: 42px;
    }
    .cart-end-sum-wrapper .end-price .figure-small{
        font-size: 22px;
    }
    .cart-end-sum-wrapper .end-price-desc{
        top: 5px;
    }
    .cart-panel td.whole-price-col,
    .cart-panel td.price-col,
    .cart-panel .show-xs{
        padding-right: 27px;
    }
    .cart-panel a.btn-delete{
        font-size: 16px;
    }
}

.delivery-box {
    background: #f5f5fa;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.delivery-box + .delivery-box {
    margin-top: 10px;
}

.delivery-box--disabled {
    opacity: .5;
}

.delivery-box__price {
    margin-left: auto;
}

.delivery-box__icon {
    min-width: 80px;
    text-align: center;
}

.delivery-box__checkbox-label {
    display: flex;
}

@media screen and (min-width: 768px){
    .img-width{
        width: 11%;
        max-width: 160px;
    }
    .icon-width{
        width: 1%;
    }
    .delivery-table__td-checkbox {
        width: 5%;
    }
    .delivery-table__td-icon {
        width: 7%;
    }
    .icon-padding {
        padding: 0 20px 0 10px;
    }

    .delivery-box__checkbox.checkbox-wrap label:before,
    .delivery-box__checkbox.delivery-box__checkbox.checkbox-wrap label:after {
        top: 50%;
        transform: translateY(-50%);
    }
    .delivery-box__checkbox-label {
        align-items: center;
    }
}

/* WARENKORB --> ACTIVE LOCATION --> small
 ----------------------------------------- */
.current-location__small {
    padding: 10px;
    display: block;
    color: #000;
    border: 1px solid #118516;
    margin: 0 0 10px 0;
    background: white;
    position: relative;
}
@media screen and (min-width: 768px ) {
    .current-location__small {
        float: right;
        padding: 15px;
        margin: 0 0 15px 0;
    }
}

.active-location{
    border: 1px solid var(--color-green);
    height: 100%;
}
.form-location-search--cart input#search-locations
{
    height: 40px;
}
.form-location-search--cart button.btn
{
    height: 40px;
    width: 40px;
}
.form-location-search--cart #search-locations{
    font-size: 18px;
}
.location-finder-results--cart .item__inner .item__head--name{
    font-family: museo_700;
}
.location-result-opening-times{
    border-top: 1px solid white;
}

/* ALTERNATIVE LOCATIONS
 ---------------------- */
.alternative-location{
    background: var(--color-bg-primary);
    padding: 15px 10px 10px 10px;
    margin-top: 10px;
}

/* AVAILABLE LOCATIONS
 ---------------------- */
.available-locations {
    padding: 15px 10px 10px 10px;
    margin-top: 10px;
}
.location__info-text{
    font-family: Arial;
    font-size: 18px;
    padding: 30px 10px 0px 30px;
}
.btn-cart-choose-location{
    margin-top: 20px;
}

@media screen and (max-width: 767px ) {
    #cart-active-location {
        padding: 10px;
    }
    .available-locations {
        padding: 0;
        padding-top: 10px;
    }
    .available-locations .btn {
        font-size: 85%;
        width: 100%;
    }
    .trash-icon-xs{
        margin-bottom: 20px;
        font-size: 20px;
    }
    .table--cart .js-number-spinner .spinner-input{
        width: 100%;

    }
    .hl-fz28{
        font-size: 18px;
        line-height: 20px;
    }
    .img-width-xs{
        margin: auto;
        width: 40%;
    }
    .whole-price-col, .price-col{
        font-size: 18px;
        line-height: 22px;
    }
    .location__info-text{
        font-size: 14px;
        padding: 20px 0 0 0;
    }
    .checkbox-center{
        left: 47%
    }
    .checkbox-center .checkbox-wrap{
        margin: 0;
        padding: 0;
    }
    td.transport-options{
        padding-bottom: 0px !important;
    }

    .delivery-box__price {
        flex: 1 0 100%;
        margin-top: 10px;
    }

    .delivery-box__icon {
        min-width: 65px;
    }
    .delivery-box__checkbox.checkbox-wrap label:before{
        top: 20px;
    }
    .delivery-box__checkbox.delivery-box__checkbox.checkbox-wrap label:after {
        top: 16px;
    }

}

@media screen and (max-width: 1100px){
    .whole-price-col{
        font-size: 16px;
    }
    .price-col{
        font-size: 16px;
    }
}


/*cart voucher*/
.table--cart__voucher {
    margin-top: 20px;
}
@media screen and (min-width: 768px) {
    .table--cart__voucher {
        margin-top: 45px;
        margin-bottom: 15px;
    }
}
.voucher-modal .modal-dialog {
    border: 1px solid #118516;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
}
@media screen and (min-width: 768px) {
    .voucher-modal .modal-dialog {
        width: 950px;
    }
    .voucher-modal .modal-header {
        padding: 42px 48px 30px;
    }
}
.voucher-modal .modal-header {
    border-bottom: 0;
}
.voucher-modal .modal-header__close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 16px;
}
@media screen and (min-width: 768px){
    .voucher-modal .modal-header__close {
        right: 30px;
        top: 30px;
    }
}
.voucher-modal .modal-title {
    font-size: 18px;
    line-height: calc(46/38);
    color: var(--color-green);
    margin-bottom: 4px;
}
@media screen and (min-width: 768px) {
    .voucher-modal .modal-title {
        font-size: 38px;
    }
    .voucher-modal .modal-body {
        padding: 48px;
    }
}
.voucher-modal .modal-body {
    padding-top: 0;
}