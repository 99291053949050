.select-box {
    background: #F5F5FA;
    padding: 10px;
    padding-left: 40px;
    cursor: pointer;
    margin: 0;
    font-family:var(--font-headline-bold);
    font-weight: 400;
}
@media screen and (min-width: 768px){
    .select-box {
        padding: 30px;
        padding-left: 85px;
    }
}
.select-box + .select-box {
    margin-top: 10px;
}
.select-box__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.select-box__box {
    position: absolute;
    overflow: hidden;
    border: 1px solid #9C9FA3;
    background: #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.select-box__box:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--color-green);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    visibility: hidden;
}
@media screen and (min-width: 768px) {
    .select-box__box {
        width: 26px;
        height: 26px;
        left: 30px;
    }
    .select-box__box:before {
        width: 14px;
        height: 14px;
    }
}
.select-box__input:checked ~ .select-box__box:before {
    visibility: visible;
}
.select-box__outline {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 1px solid var(--color-green);
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in-out;
}
.select-box__input:checked ~ .select-box__outline,
.select-box:hover:not(.select-box--disabled) .select-box__outline,
.select-box__input:focus ~ .select-box__outline {
    opacity: 1;
}
.select-box__text {
    align-items: center;
}
@media screen and (min-width: 768px) {
    .select-box__text {
        font-size: 20px;
        line-height: calc(24/20);
    }
}
.select-box__text__sm {
    font-size: 14px;
}
.select-box__text__old-price {
    font-size: 11px;
    text-decoration: line-through;
    color: var(--color-dark-grey);
}
@media screen and (min-width: 786px) {
    .select-box__text__old-price {
        font-size: 16px;
        margin-top: 5px;
    }

}
.select-box__text__info {
    font-size: 13px;
    line-height:calc(18/13);
    margin-top: 8px;
}
.select-box__text__info__icon {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.4);
    background: var(--color-bg-third);
    padding: 6px;
    display: block;
    float: left;
    text-align: center;
    margin-right: 8px;
}
@media screen and (min-width: 768px) {
    .select-box__text__info__icon {
        margin-top: -4px;
    }
}

.select-box--disabled {
    cursor: default;
}
@media screen and (max-width: 767px) {
    .select-box--disabled {
        padding-left: 10px;
    }
}
.select-box--disabled img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}