@media screen and (min-width: 768px) {
    #nav--user-console {
        position: relative;
    }
}

.has-account-settings .account-item {
    display: block;
}

.border-left{
    border-left: 1px solid var(--color-light-grey);
}
.border-bottom{
    border-bottom: 1px solid var(--color-light-grey);
}

#user-flyout {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    /*height: 1px;*/
}
#user-flyout #user-flyout__arrow {
    content: '';
    position: absolute;
    top: -9px;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px;
    border-color: transparent transparent #118516;
    transition: all 0.5s ease;
    display: none;
}
#user-flyout .dropdown-menu + #user-flyout__arrow {
    display: block;
}

.has-account-settings .account-item #user-flyout .dropdown-menu {
    display: block;
}
#user-flyout .dropdown-menu {
    display: block;
}
@media screen and (min-width: 768px){
    #user-flyout .dropdown-menu {
        width: 700px;
    }
    /* wishlist - cart */
    #user-flyout .cart-dropdown {
        width: 400px;
        padding: 20px;
        overflow: auto;
        max-height: calc(100vh - 100px);
    }
}

/* all dropdowns */
#user-flyout .dropdown-menu{
    position: absolute;
    border: 1px solid #118516;
    padding: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
@media screen and (min-width: 1230px) {
    #user-flyout .dropdown-menu{
        /*padding: 0;*/
    }
}

#user-flyout .form-group {
    margin-bottom: 10px;
}

/* login - user*/
#user-flyout .login-dropdown .input-panel {
    margin: 0;
    padding: 24px 20px;
    width: 100%;
    height: auto;
    position: relative;
}

#user-flyout .user-panel {
    padding: 10px 0;
    margin: 35px 0;
}

    /* logged in */
#user-flyout .login-dropdown.is--logged-in .box-icon {
    font-size: 22px;
}

#user-flyout .login-dropdown.is--logged-in .box-headline {
    color: #118516;
}

/* CART DROPDOWN */
@media screen and (max-width: 767px) {
    #user-flyout {
        margin-right: 10px;
    }

    #nav--user-console .dropdown-menu {
        color: #2b3133;
    }

    #user-flyout .cart-dropdown {
        padding: 10px;
    }
}

.fly-out-cart-items {
    overflow-y: auto;
    max-height: 500px;
    max-height: 50vh;
}

.fly-out-cart-item {
    margin: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.fly-out-cart-item + .fly-out-cart-item {
    border-top: 1px solid #e2e2e8;
}


.fly-out-cart-item .item-name {
    color: #118516;
}

#user-flyout .cart-dropdown .result-col {
    border-top: 1px solid #e2e2e8;
    text-align: right;
    padding-top: 10px;
    margin-bottom: 15px;
}

.account-dropdown__register-link {
    font-size: 18px;
    line-height:calc(28/18);
    text-decoration: underline;
}
.account-dropdown__register-link>.icon {
    font-size: 9px;
    margin-right: 5px;
}
.account-dropdown .input-panel h4 {
    font-size: 20px;
}

/* expert
.user-flyout .account-dropdown{
    color: #212121;
}

.user-flyout label{
    font-weight: normal;
    font-family: FoundationSansBold, sans-serif;
}

.user-flyout .account-dropdown a{
    color: var(--color-primary);
}

.user-flyout .btn{
    position: relative;
}

.user-flyout .btn:hover,
.user-flyout a.btn{
    color: #fff;
}

.user-flyout .login-dropdown .input-panel {
    margin: 0 0 25px;
    padding: 25px;
    width: 100%;
    height: 250px;
    border: 1px solid #d9dada;
    position: relative;
    margin-bottom: 0;
    padding-top: 16px;
}

.user-flyout .login-dropdown h2{
    font-size: 20px;
}


.user-flyout .account-dropdown.login-dropdown.dropdown-menu{
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);

    margin-left: -340px;
}


.user-flyout .account-dropdown .btn{
    font-size: 15px;
    font-family: var(--font-default-family);
    min-height: initial;
}

.user-flyout .login-dropdown .popup-contact-info{
    position: absolute;
    bottom: 0;
}

.user-flyout .login-dropdown.dropdown-menu:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -9px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 10px;
    border-color: transparent transparent #fff;
}


/* Admin popup /

.user-flyout .account-dropdown.dropdown-menu:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: 118px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 9px 10px;
    border-color: transparent transparent #fff;
}

.user-flyout .account-dropdown.dropdown-menu {
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    min-width: 380px;
    margin-left: -65px;
}

.user-flyout .account-dropdown.dropdown-menu ul li.signout {
    background: #eaeaea;
    margin: 10px -20px -20px;
    padding: 5px 20px;
}

.user-flyout .account-dropdown.dropdown-menu ul li>a{
    display: block;
    /*padding: 1px 4px;/
    position: relative;
}


/* Cart popup /

.account-dropdown.dropdown-menu.cart-simple {
    left: auto;
    right: 0;
    min-width: 360px;
}

.user-flyout .account-dropdown.dropdown-menu.cart-simple .result-col{
    text-align: right;
    border-top: 1px solid #d9dada;
    padding-top: 10px;
}


.user-flyout .account-dropdown.dropdown-menu.cart-simple:before {
    left: auto;
    right: 40px;
    margin-left: 0;
}

/* Wishlist /


.user-flyout .wishlist.account-dropdown.dropdown-menu{
    margin-left: 92px;
}

.user-flyout .is-in-view{
    animation-name: fade;
    animation-duration: .25s;

}


/* Media query /

@media screen and (min-width: 768px) and (max-width: 992px){
    .user-flyout .account-dropdown .btn{
        padding: 7px 10px 7px;
        font-size: 12px;
    }

    .account-dropdown.dropdown-menu.cart-simple{
        right: 75px;
    }

    .user-flyout .account-dropdown.login-dropdown.dropdown-menu{
        margin-left: -48px;
    }
}

@media screen and (min-width: 992px){
    .user-flyout .account-dropdown.login-dropdown.dropdown-menu{
        min-width: 700px;
    }
}
*/