#contactBox {
    padding: 10px;
    color: var(--color-green);
    background: none;
}
#contactBox .contactBox__data {
    margin-bottom: 20px;
    position: relative;
}
.contactBox__data .em-holder {
    max-width:160px;
    border-radius: 50%;
    overflow: hidden;
}


.data-holder__name {
    padding: 4px 10px;
    background: none;
    font-family: Arial, sans-serif;
    font-weight: bold;
    color: var(--color-green);
    margin: 5px 0;
}

.data-holder__desc {
    padding: 4px 10px;
}


@media screen and (max-width: 767px) {

    #contactBox {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #contactBox .contactBox__data {
        text-align: center;
    }
    .contactBox__data .em-holder {
        max-width: 150px;
        margin: 0 auto 10px auto;
    }
    .contactBox__data .data-holder {
        position: relative;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
    }
    .contactBox__data .data-holder .data-holder__name {
        margin: 10px -10px;
        width:auto;
    }
    .contactBox__data .data-holder .data-holder__desc {
        margin: 10px -10px;
        display: inline-block;
        width:auto;
    }
}

@media screen and (min-width: 768px) {
    /*.contactBox__data .data-holder {*/
        /*position: absolute;*/
        /*right: 0;*/
        /*top: 0;*/
        /*overflow: hidden;*/
    /*}*/

    /*.data-holder__desc {*/
        /*margin-left: 45px;*/
    /*}*/
}

@media screen and (min-width: 992px) {

    #contactBox {
        padding: 15px;
    }

    .contactBox__data .em-holder {
        max-width: 110px;
    }

}


@media screen and (min-width: 1200px) {

    #contactBox {
        padding: 20px;
    }

    .contactBox__data .em-holder {
        max-width: 160px;
    }

    /*.contactBox__data .data-holder {*/
        /*right: 20px;*/
        /*top: 10px;*/
    /*}*/

}


/* contact-list */
.nav-contact-list {
    line-height: 1;
}

.nav-contact-list ul li > a {
    padding: 10px 0;
}


@media screen and (max-width: 767px) {
    .nav-contact-list {
        width: 80%;
        margin: 0 auto;
    }
    .nav-contact-list .icon {
        font-size: 27px;
        margin-right: 15px;
    }
    .nav-contact-list ul {
         margin: 0;
    }
    .nav-contact-list ul li a.flex-wrapper {
        justify-content: center;
        margin-bottom: 10px;
        background-color: var(--color-green);
        color: #fff;
    }
    .nav-contact-list .icon {
        font-size: 18px;
    }

    .contactBox__data .data-holder .xs-on-block {
        display: block;
        padding-left: 10px;
    }
}

@media screen and (min-width: 768px) {

    .nav-contact-list .icon {
        font-size: 30px;
        margin-right: 15px;
        min-width: 45px;
    }
    .nav-contact-list li .btn-block + .btn-block {
        margin-top: 5px;
    }

}



/* CONTACTBOX -FORM
 ----------------- */
.contactbox__form-wrapper {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #029138;
}
.contactbox__form-wrapper__form {

}


.dropdown--contactBox-form .dropdown-toggle {
    background: #029138;
}
.dropdown--contactBox-form.open > .dropdown-toggle .caret {
    transform: rotate(180deg);
}
.dropdown--contactBox-form > .form {
    display: none;
}
.dropdown--contactBox-form.open > .form {
    display: block;
}

/* form */
.form--cBox {
    margin: 15px 10px 10px 10px;
}







