#site-footer {
    padding: 0px;
    margin-top:15px;
}

/* FOOTER - SECTIONS  */
@media screen and (max-width: 767px) {

    .footer-section {
        padding: 15px 0px;
    }

    #site-footer {
        display: flex;
        flex-direction: column;
    }
    .footer-section--services {
        order: 1;
        padding-left: 5px;
    }
    .footer-section--links {
        order: 2;
        padding: 0;
    }
    .footer-section--social {
        order: 3;
        padding-left: 5px;
        background: #fff;
    }
    .footer-section--info {
        order: 4;
    }
    .footer-section--info .row .col.footer-info__copy,
    .footer-section--info .row .col.footer-info__elements {
        padding-left: 65px;
    }
}


@media screen and (min-width: 768px) {

    .footer-section {
        padding: 15px 0;
    }

    .footer-section.footer-section--info {
        padding: 10px 0;
    }

    .footer-section.footer-section--social {
        padding: 10px 0;
        margin-bottom: 4px;
    }

}


@media screen and (min-width: 768px) and (max-width: 1600px) {
    .footer-section.footer-section--info {
        padding-left: 60px;
    }
}

@media screen and (min-width: 992px) {

    .footer-section {
        padding: 30px 0;
    }
    .footer-section.footer-section--social {
        padding: 15px 0;
    }
    .footer-section.footer-section--info {
        padding: 15px 0;
    }

}


@media screen and (min-width: 1200px) {

    .footer-section {
        padding: 50px 0;
    }
    .footer-section.footer-section--social {
        padding: 10px 0;
    }
    .footer-section.footer-section--info {
        padding: 20px 0;
    }

}


/* FOOTER - INFO - BLOCK */

.container--footer-info .footer-info__links ul > li  {
    padding: 0;
}


@media screen and (max-width: 767px) {
    .container--footer-info > .row {
        display: flex;
        flex-direction: column;
    }

    .container--footer-info > .row > .col {
        padding-left: 10px;
        padding-right: 10px;
    }

    .container--footer-info .footer-info__links {
        order: 1;
    }
    .container--footer-info .footer-info__links nav {
        border-bottom: 1px solid #d0d0da;
        padding-bottom: 5px;
    }
    .container--footer-info .footer-info__links ul {
        margin: 0;
    }
    .container--footer-info .footer-info__links ul > li > a  {
        padding: 0px 10px;
    }
    .container--footer-info .footer-info__links ul > li:first-of-type > a  {
        padding-left: 0;
    }
    .container--footer-info .footer-info__copy {
        order: 2;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
    }
    .container--footer-info .footer-info__elements {
        order: 3;
    }

}

@media screen and (min-width: 768px) {

    .container--footer-info .footer-info__links {
        text-align: right;
    }
    .container--footer-info .footer-info__links ul {
        margin: 0;
    }
    .container--footer-info .footer-info__links ul > li > a  {
        padding: 0px 10px;
    }
    
    .footer-info__elements {
        text-align: right;
    }

}

@media screen and (min-width: 1200px) {

    .container--footer-info .footer-info__links ul > li > a  {
        padding: 0px 15px;
    }

}

@media screen and (min-width: 768px) {
    .link--elements {
        transition: all 0.6s ease;
        justify-content: flex-end;
    }
}

html.desktop .link--elements .icon {
    font-size: 22px;
}
html.desktop .link--elements {
    transition: all 0.6s ease;
    justify-content: flex-end;
}
html.desktop .link--elements:hover {
     color: #ff3300;
    transition: all 0.25s ease;
}
html.desktop .link--elements > .icon {
    transform: rotate(0deg) scale(1);
    transition: all 0.5s ease;
}
html.desktop .link--elements:hover > .icon {
    transform: rotate(-180deg) scale(1.15);
    transform-origin: center center;
    transition: all 0.25s ease;
}



/*------------------------- Footer Anpassungen nach AB Test -----------------*/
.footer__icon-list {
    display: flex;
    align-items: center;
}
.footer__icon-list li {
    display: inline;
}

#nav--footer-contact .footer__icon,
#nav--footer-contact .footer__icon-small {
    margin-right: 10px;
    min-width: auto;
}
#nav--footer-contact .footer__icon-small {
    font-size: 25px !important;
}
#nav--footer-contact .footer__icon-list li:not(:last-of-type)>a,
#nav--footer-contact .footer__icon-list li+li>a {
    border: none;
}

@media screen and (min-width: 768px) {
    .footer-collapse__link {
        pointer-events: none !important;
    }
    .footer-collapse__body {
        display: block !important;
    }
}


@media screen and (max-width: 767px) {
    .footer-collapse h3 {
        margin: 0;
    }
    .panel-title__icon-right {
        right: 5px;
    }
    .footer-collapse__link {
        position: relative;
        display: block;
    }
    .footer-collapse__body {
        margin-top:10px;
    }
    #nav--footer-contact .footer__icon-list li>a {
        padding-bottom: 0;
        border: 0;
    }
    #nav--footer-contact .footer__icon-list li {
        border: 0;
    }
    .footer-social {
        padding: 15px 5px;
        background: #f5f5fa;
        margin-bottom: 5px;
    }
    #nav--footer-contact ul li {
        border-top: 0;
        border-bottom: 5px solid #fff;
    }
}

@media screen and (max-width: 767px) {
    .nav--footer-links__payment {
        border-top: 5px solid #fff;
    }
    .nav--footer-links {
        background-color: #f5f5fa;
        padding: 15px 5px;
        border-bottom: 5px solid #fff;
    }
    .nav--footer-links__payment-list {
        columns: 2;
    }
}