.panel-primary {
    background: var(--color-bg-primary);
    border: 0;
    position: relative;
}
.panel-primary .panel-title {
    font-size: 18px;
    position: relative;
}
.panel-primary .panel-body {
    border: 0;
}
@media screen and (min-width: 768px) {
    .panel-primary .panel-body {
        padding: 20px;
        padding-top: 0;
    }
}
.panel-primary__border {
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    height:2px;
    background: #fff;
}
.collapse.in + .panel-primary__border, .collapsing + .panel-primary__border{
    transform: scaleY(-1);
}
.panel-primary .panel-title a {
    position: relative;
}
@media screen and (min-width: 768px) {
    .panel-primary .panel-title a {
        padding: 10px 20px;
    }
    .panel-primary__border {
        left: 20px;
        right: 20px;
    }
}
.panel-primary:last-child .panel-primary__border{
    display: none;
}
.panel-primary__border:after, .panel-primary__border:before {
    content: '';
    position: absolute;
    right: 15px;
    top: 0;
    border-style: solid;
    border-width: 9px 7px 0 7px;
    border-color: #fff transparent transparent transparent;
    z-index: 1;
}
.panel-primary__border:before {
    right: 16px;
    top: -1px;
    border-width: 7px 6px 0 6px;
    border-color: var(--color-bg-primary) transparent transparent transparent;
    z-index: 2;
}
@media screen and (min-width: 768px) {
    .panel-primary__border:after, .panel-primary__border:before {
        right: 30px;
        border-width: 14px 14px 0;
    }
    .panel-primary__border:before {
        right: 32px;
        border-width: 12px 12px 0;
    }
}
.panel-group .panel-primary + .panel-primary {
    margin-top: 0;
}
.panel-body--no-padding {
    padding: 0;
}
.panel-body--padding-lg {
    padding: 20px;
}
@media screen and (min-width: 768px) {
    .panel-body--padding-lg {
        padding: 30px;
    }
}
.panel-group .panel-default--border + .panel-default--border {
    margin-top: 10px;
}
@media screen and (min-width: 768px) {
    .panel-group .panel-default--border + .panel-default--border {
        margin-top: 15px;
    }
}
.panel-default--border .panel-title a {
    font-size: 20px;
    padding: 14px 14px 10px;
    position: relative;
}
@media screen and (min-width: 768px) {
    .panel-default--border .panel-title a {
        padding: 11px 19px;
    }
    .panel-default--border .panel-body {
        padding: 12px 19px;
    }
}
.panel-default--border {
    border: 1px solid #d9d9e1;
}
.panel-default--border .panel-body {
    border: 0;
    padding: 10px 14px;
}
.panel-default--border .panel-heading + .panel-body {
    border-top:1px solid #d9d9e1;
}
.panel-title__icon-right {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
}
.panel-title__icon-right:before {
    display: block;
    transform: rotate(180deg);
    transition: transform 250ms ease-in-out;
}
.collapsed .panel-title__icon-right:before {
    transform: rotate(0);
}
.panel-heading__col{
    padding: 15px;
    font-size: 18px;
}
@media screen and (min-width: 786px) {
    .panel-heading__col {
        padding: 10px 20px;
        display: inline-block;
        font-size: 20px;
    }
}
.panel-heading__col--right {
    font-size: 14px;
    border-top:1px solid #d9d9e1;
    padding: 10px 15px;
}
@media screen and (min-width: 786px) {
    .panel-heading__col--right {
        font-size: 18px;
        border-top: 0;
        border-left:1px solid #d9d9e1;
        float: right;
        padding: 13px 20px 10px;
    }
}
.panel-body__hl {
    font-size: 18px;
    line-height:20px;
    margin-bottom: 10px;
}
@media screen and (min-width: 786px) {
    .panel-body__hl {
        font-size: 28px;
        line-height:calc(30/28);
        margin-bottom: 20px;
    }
}