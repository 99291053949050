@media screen and (min-width: 768px) {

    .container--click-collect {
        box-shadow: 0 -5px 10px rgba(0,0,0,0.10) inset;
        padding: 0 0  10px 0;
    }

    .container--click-collect .cc-header-top {
        padding: 10px 0 40px 0;
        background: linear-gradient(180deg, white 0%, #f5f5fa 100%);
    }

    /* headline */
    .cc-header-top__headline {
        margin: 15px 0 5px 0;
        font-size: 22px;
        color: #585d5f;
    }
    .cc-header-top__headline span {
        text-transform: uppercase;
        color: var(--color-green);
        font-family: 'interstate_regular', serif;
    }

    /* header-bottom */
    .cc-header-bottom .step-wrapper {
        padding: 0 25px;
    }
    .cc-header-bottom .step-wrapper header {
        background: #fff;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        margin-top: -38px;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .cc-header-bottom .step-wrapper header .icon {
        font-size: 40px;
        color: var(--color-light-grey);
    }
    .cc-header-bottom .step-wrapper {
        color: var(--color-dark-grey);
    }

    .cc-header-bottom .step-wrapper main {
        font-size: 14px;
    }

    .cc-header-bottom h4 {
        text-transform: uppercase;
        font-family: 'interstate_regular', serif;
    }
    .cc-header-bottom h4 span {
        font-size:34px;
        margin-right: 10px;
    }


    /* ANIMATIONS */
    /*header*/
    .cc-header-bottom .step-wrapper header {
        animation-duration: 0.4s;
        animation-delay: 0.4s;
        opacity: 0;
        animation-name: fadeInUp;
        animation-fill-mode: forwards;
    }


    /*main*/
    .cc-header-bottom .step-wrapper main {
        animation-duration: 0.4s;
        animation-delay: 0.4s;
        opacity: 0;
        animation-name: fadeInDown;
        animation-fill-mode: forwards;
    }
    .cc-header-bottom .step-wrapper:nth-of-type(2) main,
    .cc-header-bottom .step-wrapper:nth-of-type(2) header {
        animation-delay: 1.2s;
    }
    .cc-header-bottom .step-wrapper:nth-of-type(3) main,
    .cc-header-bottom .step-wrapper:nth-of-type(3) header {
        animation-delay: 1.9s;
    }

    /*seperator*/
    .cc-header-bottom .step-wrapper .step-seperator {
        position: absolute;
        right: 0;
        top: 55px;
        font-size: 50px;
        color: var(--color-light-grey);

        animation-duration: 0.25s;
        animation-delay: 0.8s;
        opacity: 0;
        animation-name: fadeInLeft;
        animation-fill-mode: forwards;

    }

    .cc-header-bottom .step-wrapper:nth-of-type(2) .step-seperator {
        animation-delay: 1.6s;
    }

    /* show-stores */
    .cc-header-bottom .btn-show-stores {
          opacity: 0;
          animation-name: fadeInUp;
          animation-duration: 0.65s;
          animation-delay: 2.5s;
          animation-fill-mode: forwards;
    }
    #container--click-collect__close {
        opacity: 0;
        animation-name: fadeIn;
        animation-duration: 0.20s;
        animation-delay: 3.8s;
        animation-fill-mode: forwards;
    }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .cc-header-top figure > img { width: 75%; }
}

@media screen and (min-width: 992px) {

    .container--click-collect .cc-header-top {
        padding: 20px 0 40px 0;
        background: linear-gradient(180deg, white 0%, #f5f5fa 100%);
    }

    .cc-header-top__headline {
        margin: 20px 0 5px 0;
        font-size: 27px;
    }
    .cc-header-bottom .step-wrapper {
        padding: 0 35px;
    }
}


@media screen and (min-width: 1200px) {

    .container--click-collect .cc-header-top {
        padding: 40px 0 70px 0;
    }

    .cc-header-top__headline {
        font-size: 30px;
    }

    .cc-header-bottom .step-wrapper {
        padding: 0 50px;
    }

}

/* temo */
@media screen and (min-width: 768px) {
    #header-main.is-fixed {
        position: fixed;
        z-index: 200;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        transform: translateZ(0);
        -webkit-animation-duration: 0.35s;
        animation-duration: 0.35s;
        -webkit-animation-name: slideInDown;
        animation-name: slideInDown;
    }
}
