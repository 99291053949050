.lagerhaus-tabs {
    display: inline-block;
}

.prod-detail-tabs .lagerhaus-tabs {
    display: block;
}

.lagerhaus-tabs.lagerhaus-tabs--search {
    display: block;
    text-align: center;
}

.lagerhaus-tabs.lagerhaus-tabs--search .nav li {
    float: none;
    display: inline-block;
}

.lagerhaus-tabs.lagerhaus-tabs--search .nav li a {
    font-size: 22px;
    text-transform: none;
}
@media screen and (max-width: 767px) {
    .lagerhaus-tabs.lagerhaus-tabs--search {
        text-align: left;
    }
    
    .lagerhaus-tabs.lagerhaus-tabs--search .nav li a {
        font-size: 15px;
    }
    .lagerhaus-tabs.lagerhaus-tabs--search .nav li {
        display: block;
    }

    .lagerhaus-tabs.lagerhaus-tabs--search .nav-tabs>li.active>a {
        border-bottom: 1px solid #e8e8ed;
    }
    
}

.lagerhaus-tabs.lagerhaus-tabs--search .nav li.active a {
    background-color: #f5f5fa;
}

.lagerhaus-tabs .nav li a {
    font-family: 'museo_700';
    text-transform: uppercase;
}

.tab-content {
    padding: 10px;
}

@media screen and (min-width: 768px) {

    .tab-content {
        padding: 20px;
    }


}

@media screen and (max-width: 767px) {

    .lagerhaus-tabs .nav li a {
        padding: 5px 7px;
        font-size: 12px;
    }
    .lagerhaus-tabs .nav-tabs {
        border: none;
    }
    .lagerhaus-tabs .nav-tabs > li.active > a {
        border-bottom: 1px solid;
    }

    .prod-detail-tabs .panel-group {
        margin-left: 5px;
        margin-right: 5px;
    }
    .prod-detail-tabs .panel-group .panel-title a {
          font-family: Arial;
          font-weight:normal;
    }
    .prod-detail-tabs .panel-group .panel-title a:after {
        content: "\E005";
        font-family: "iconfont";
        font-size: 8px;
        float: right;
        position: relative;
        top: 4px;
    }
    .prod-detail-tabs .panel-group .panel-title a.collapsed:after {
        content: "\E001";
    }

}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus {
    border-bottom:1px solid #fff;
}