/**
    all categories
*/

@media screen and (min-width: 768px) {

    .nav-category li.all-categories > a {

        font-family: 'museo_700';
        font-size: 20px;
        margin-bottom: 20px;
    }

    .nav-category li.all-categories {
        border-bottom: 1px solid #E2E2E8;
    }

    .nav-category li.all-categories + .level-1-category {
        margin-top: 20px;
    }
}

/*
.nav-category li.level-1-category.sub-link > a {
    margin-top: 0;
}

.nav-category li.level-1-category > a {
    margin-top: 20px;
}

.nav-category li.level-2-category > a {
    padding-left: 20px;
}

.nav-category li.level-2-category.active > a {
    padding-left: 40px;
}

.nav-category li.level-2-category.sub-link > a {
    padding-left: 40px;
}


.nav-category li.level-3-category > a {
    padding-left: 40px;
}

.nav-category li.level-3-category.active > a {
    padding-left: 60px;
}

.nav-category li.level-3-category.sub-link > a {
    padding-left: 60px;
}

.nav-category li.active > a {
    font-family: 'museo_700';
    padding-left: 20px;
}*/
.nav-category ul li.activeLink a {
    font-family: 'museo_700';
}
.nav-category ul li.active a {
    font-family: 'museo_700';
}

.nav-category ul li.activeLink a:after {
    opacity: 100;
    right: 10px;
}
.nav-category ul li.active a:after {
    opacity: 100;
    right: 10px;
}


.nav-category ul li.level-1-category a {
    padding-left:20px;
}

.nav-category ul li.level-2-category a {
    padding-left:40px;
}


.nav-category ul li.level-3-category a {
    padding-left:60px;
}

.nav-category ul li.sub-link.level-1-category a {
    padding-left:40px;
}

.nav-category ul li.sub-link.level-2-category a {
    padding-left:60px;
}

.nav-category ul li.sub-link.level-3-category a {
    padding-left:80px;
}