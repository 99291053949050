.wysiwyg {
    min-height: .01%;
}
.wysiwyg a,
.default-link {
    text-decoration: underline;
    color: var(--color-green);
    background: none;
    border: none;
    padding: 0;
}
.wysiwyg strong a {
    text-decoration: none;
}

.wysiwyg p {
    margin-bottom: 20px;
}
.wysiwyg ul,
.wysiwyg ol {
    margin-bottom: 20px;
}
.wysiwyg ol,
.wysiwyg ul {
    padding-left: 17px;
}

@media screen and (min-width: 768px) {
    .wysiwyg ol,
    .wysiwyg ul {
        padding-left: 23px;
    }
}
.wysiwyg table:last-child,
.wysiwyg p:last-child,
.wysiwyg ul:last-child,
.wysiwyg ol:last-child {
    margin-bottom: 0;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
    margin-top: 1em;
    margin-bottom: 0;
}

.wysiwyg table:first-child,
.wysiwyg h1:first-child,
.wysiwyg h2:first-child,
.wysiwyg h3:first-child,
.wysiwyg h4:first-child,
.wysiwyg h5:first-child,
.wysiwyg h6:first-child {
    margin-top: 0;
}

/* checkmark list */
.wysiwyg .checkmark-list {
    list-style: none;
    padding: 0;
}
.wysiwyg .checkmark-list>li {
    font-family: 'interstate_regular', serif;
    position: relative;
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0 0 0 30px;
}
.wysiwyg .checkmark-list>li:before {
    content:'';
    display: block;
    position: absolute;
    left:7px;
    top:1px;
    width:8px;
    height:15px;
    border:2px solid var(--color-green);
    border-width:0 2px 2px 0;
    transform: rotate(45deg);
}