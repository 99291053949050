.rent-machines-list div.rent-item:last-child{
    border-bottom: 1px solid #efefef;
}

.rent-item {
    border-top: 1px solid #efefef;
    padding: 20px 0px;
    position: relative;
}

.text-block{
    font-size: 18px;
    line-height: 28px;
}

.rent-item__img{
    top: 10px;
}

.rent-item__heading{
    font-family: 'museo_700', sans-serif;
    color: #118516;
    line-height: 29px;
    font-size: 24px;
}

.rent-item__info-text{
    color: #2b3133;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

.rent-item__info-text__list ul{
    padding-left: 17px;
}

.choose-rent-machine-btn{
    margin: 15px 0;
}

.rent-item__img-wrapper{
    right: 0;
}

.change-rent-machine{
    color: #118516;
    text-decoration: underline;
    cursor: pointer;
}

.border-top{
    border-top: 1px solid #efefef;
    padding-top: 30px;
}
.form__heading-column{
    border-bottom: 1px solid #dadada;
    padding-bottom: 20px;
    margin-left: 10px;
}

@media screen and (max-width: 768px){
    .rent-item__info-text{
        font-size: 14px;
        line-height: 20px;
    }
    .choose-rent-machine-btn{
        width: 100%;
    }
    .btn-send-request{
        width: 100%;
    }
}