.product-detail__cart .item {
    padding: 5px;
}
.product-detail__cart .item ~ .item {
    margin-top: 1px;
}

.product-detail__cart .cart__price-wrapper {
    line-height: 1;
    margin-top: 5px;
    margin-bottom: 5px;
}

.product-detail__cart .price-wrapper__price-per-unit {
    font-size: 11px;
    margin-bottom: 20px;
}

.product-detail__cart .cart__price {
    font-size: 42px;
}

.price-font-size {
    font-size: 42px;
}

.product-detail__cart .cart__vat {
    align-self: flex-end;
    margin-bottom: 3px;
}

.btn--add-to-cart {
    letter-spacing: 1px;
    font-family: 'interstate_regular', serif;
    background-color: var(--color-apple-green);
}
@media screen and (min-width: 768px) {
    .btn--add-to-cart {
        font-size: 16px;
    }
}
@media screen and (min-width: 1400px) {
    .btn--add-to-cart {
        font-size: 20px;
    }
}
.product-item .btn--add-to-cart {
    letter-spacing: normal;
    font-family: 'museo_500';
}
@media screen and (min-width: 768px) {
    .product-item .btn--add-to-cart {
        font-size: 16px;
    }
}

@media screen and (max-width: 767px) {

    .col.col-has-cart {
        padding: 0;
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 15px;
    }
    .col.col-has-cart .product-detail__cart {
        padding: 15px;
        background-color: var(--color-bg-primary);
    }

}

@media screen and (min-width: 768px) {
    
    /* CART */
    .price-v-top {
        font-size: 50%;
        vertical-align: super;
    }
    .product-detail__cart .item {
        padding: 15px;
    }

    
}

@media screen and (min-width: 1200px) {

    .product-detail__cart .item {
        padding: 20px;
    }

}

/* NUMBER SPINNER
 ---------------- */
.spinner-input {
    width:50px;
    text-align: center;
    padding: 0;
}

/* CART RESERVATION DROPDOWN */
.dropdown--cart {

}

.color-indicator {
    display: inline-block;
    margin: 0 2px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #1b6d3c;
    position: relative;
    top: 1px;
}
.color-indicator.color-indicator--orange {
    background: #e49439;
}

#dropdownMenuCart {
    text-align: left;
    font-family: Arial;
}
.dropdown--cart-cc-location .caret {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}

/* dropdown-- */
.dropdown--cart-cc-location .dropdown-menu {
    width: 100%;
    padding: 0;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.35);
}
.dropdown--cart-cc-location .dropdown-menu > li > a {
    padding: 5px 12px;
}
.dropdown--cart-cc-location .dropdown-menu > li:hover > a {
    background: #f5f5fa;
}

.location-wrapper--full {
    float: none;
    margin-right: 0;
    min-width: auto;
}
.location-wrapper--full .dropdown-toggle {
    background: #fff;
    width: 100%;
    margin: 5px 0 0;
    padding: 10px;
    border: 1px solid #e2e2e8;
}
.dropdown-toggle .dropdown-toggle__icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 6px;
}
.location-wrapper.location-wrapper--full .dropdown-menu {
    width: 100%;
    padding: 5px 10px 20px;
    font-size: 13px;
    border: 1px solid #e2e2e8;
}
.location-wrapper--full .dropdown-menu__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 6px;
}
.location-wrapper--full .dropdown-menu a {
    padding:5px 0;
}
.location-wrapper--full .dropdown-menu li + li a {
    border-top:1px solid #e2e2e8;
}
.dropdown-menu__icon:before {
    display: inline-block;
    transition: transform 250ms ease-in-out;
}
.dropdown-menu .dropdown-menu__list {
    padding: 5px 0;
}
.location-wrapper--full .is-open a .dropdown-menu__icon:before {
    transform: rotate(180deg);
}
.location-wrapper--full .select-location {
    cursor: pointer;
    padding-right: 5px;
}
.location-wrapper--full .select-location + .select-location {
    margin-top: 5px;
}
.location-wrapper--full .select-location:hover {
    color: var(--color-green);
}
.select-location__circle {
    vertical-align: -.1em;
    margin-right: 5px;
}

.location-legend {
    margin-bottom: 5px;
    font-size: 12px;
}
.location-legend__item + .location-legend__item{
    margin-top: 5px;
}
.location-legend__item__circle {
    vertical-align: -.2em;
    margin-right: 5px;
}
.location-wrapper__btn {
    text-align: left;
}
.location-wrapper__btn .location-wrapper__btn__circle{
    vertical-align: -.1em;
    margin-left: 5px;
}
.location-optional {
    font-size: 13px;
}
.location-optional__circle {
    vertical-align: -.1em;
    margin-left: 2px;
}
.product-detail__heading {
    font-size: 15px;
}
@media screen and (min-width: 1200px) {
    .location-optional {
        font-size: 14px;
    }
}

.product-detail__cart .dropdown-toggle--meinLagerhaus + .dropdown-menu,
.location-wrapper--full .dropdown-toggle--meinLagerhaus + .dropdown-menu {
    margin-top: 10px;
    display: block;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}
.product-detail__cart .dropdown-toggle--meinLagerhaus.is-open + .dropdown-menu,
.location-wrapper--full .dropdown-toggle--meinLagerhaus.is-open + .dropdown-menu {
    display: block;
    margin-top: -2px;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transition: all 0.25s ease;
}

.product-detail__cart {
    position: relative;
}
.product-detail__cart__badge {
    position: absolute;
    right: 0;
    top: 0;
    background: #831900;
    color: #fff;
    font-style: italic;
    font-size: 14px;
    padding: 6px 10px;
    line-height: 1;
}
.cart__price__before {
    color: #585d5f;
    font-size: 11px;
    font-family:var(--font-headline-default);
    font-weight: 400;
    margin-bottom: 10px;
}