.voucher-list {
    padding-left: 0;
    display: block;
}
.voucher-list__item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
}