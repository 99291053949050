@media screen and (max-width: 767px) {
    .container.container--main {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }
}


@media screen and (min-width: 768px) {

    #sidebar {
          margin-top: 15px;
    }
    #main {
        padding-left: 25px;
    }

    #sidebar .h3-lead {
        padding-bottom: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e2e2e8;
    }

}


@media screen and (min-width: 1200px) {

    #main {
        padding-left: 55px;
    }
    #sidebar {
        margin-top: 40px;
    }
    #sidebar .h3-lead {
        padding-bottom: 11px;
        margin-bottom: 20px;
    }

}


/* SIDEBAR SUBNAVIGATION
 ----------------------- */
@media screen and (min-width: 768px) {

    #subNav ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    #subNav ul ul {
        padding: 3px 0;
    }

    #subNav ul li a {
        font-size: 17px;
        padding: 4px 0;
        display: block;
    }

    #subNav ul > li > a {
        font-family: 'museo_700';
        font-weight: normal;
        font-style: normal;
    }

    #subNav ul > li > ul > li > a {
        font-family: 'museo_500';
        font-weight: normal;
        font-style: normal;
        padding-left: 10px;
    }

}

@media screen and (min-width: 992px) {

    #subNav ul li a {
        position: relative;
        padding-right: 15px;
       /* overflow: hidden;*/
    }
    #subNav ul li a:after {
        content: "\E003";
        font-family: "iconfont";
        font-size: 10px;
        position: absolute;
        right: 25px;
        top: 50%;
        opacity: 0;
        transition: all 0.25s ease;
        transform: translateY(-50%);
    }

    #subNav ul li a:hover,
    #subNav ul li.active > a {
        background: #f5f5fa;
        transition: all 0.2s ease;
    }
    #subNav ul li a:hover:after,
    #subNav ul li.active > a:after {
        right: 10px;
        opacity: 1;
        transition: all 0.2s ease;
    }

    #subNav ul ul { display: none; }
    #subNav ul li.active ul { display: block; }

}

@media screen and (min-width: 1200px) {

    #subNav ul li a {
        font-size: 18px;
        padding: 5px 0;
    }

    #subNav ul > li > ul > li > a {
        padding-left: 20px;
        font-size: 18px;
    }

}











