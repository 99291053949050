 @media screen and (min-width: 768px) {

    /* temp */
    .row.row--paging-wrapper-top {
        margin: 0;
    }


    .paging-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .paging-wrapper--top {
        margin-bottom: 20px;
    }

    .paging-wrapper > .flex-item {
        height: 50px;
        display: flex;
        align-items: center;
    }

    .paging-wrapper > .flex-item .productgrid-ajax-link {
        min-width: 30px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
     .paging-wrapper > .flex-item .productgrid-ajax-link:hover {
         background: #ebebef;
         transition: background 0.1s;
     }

     .paging-wrapper > .flex-item .productgrid-ajax-link.active {
         background: #ebebef;
     }

     /*.paging-wrapper > .flex-item.flex-item--has-arrows {*/
         /*padding-right: 0;*/
     /*}*/
     .paging-wrapper > .flex-item.flex-item--has-arrows .productgrid-ajax-link {
         border: none;
         border-left: 2px solid #fff;
         display: flex;
         align-items: center;
         padding: 0 15px;
         font-size: 11px;
         width: 40px;
     }
     .paging-wrapper > .flex-item.flex-item--has-arrows .productgrid-ajax-link:first-of-type {
         padding: 0;
     }


     /* sort-dropdown */
     .paging-wrapper > .flex-item.flex-item--has-dropdown {
         align-items: stretch;
         padding-right: 0;
     }
     .paging-wrapper > .flex-item.flex-item--has-dropdown .dropdown-label,
     .paging-wrapper > .flex-item.flex-item--has-dropdown .is-dropdown {
         display: flex;
         align-items: center;
         flex-direction:column;
         justify-content: center;
     }
     .paging-wrapper > .flex-item.flex-item--has-dropdown .dropdown-toggler {
          display: flex;
          align-items: center;
          flex-grow: 1;
     }
     .paging-wrapper > .flex-item.flex-item--has-dropdown .is-open .dropdown-toggler {
         background: #2b3133;
         color: #fff;
     }
     .paging-wrapper > .flex-item.flex-item--has-dropdown .is-open .dropdown-toggler .caret {
         transform: rotate(180deg);
     }
     .paging-wrapper > .flex-item.flex-item--has-dropdown .is-dropdown .dropdown-menu .productgrid-ajax-link {
         min-height:30px;
     }

     .paging-wrapper > .flex-item.flex-item--has-dropdown .is-dropdown .dropdown-menu .productgrid-ajax-link:hover {
           background: #2b3133;
           color: #fff;
     }

     .paging-wrapper .paging-value{
         padding: 0 15px;
     }
}


 @media screen and (min-width: 1200px) {

     .paging-wrapper--top {
         margin-bottom: 40px;
     }

 }