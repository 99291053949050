.content-slider--same-height {
    /*display: flex;*/
}

.content-slider--same-height .slick-track {
    display: flex;
}

.content-slider--same-height:before,
.content-slider--same-height:after {
    display: none;
}

.content-slider--same-height .content-slider__item {
    display: flex;
    flex-direction: column;
    height: auto;
    outline: none;
}

.content-slider--same-height  .teaser,
.content-slider--same-height  .product-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.content-slider--same-height .teaser footer {
    flex-grow: 1;
}

/* sldiers-gutter */
@media screen and (min-width: 768px) {
    .content-slider.gutter-width-2 {
        margin-left: -2px;
        margin-right: -2px;
    }
    .content-slider.gutter-width-2 .content-slider__item {
        margin: 0 2px;
    }

}


@media screen and (max-width: 767px) {
    .container .content-slider {
        margin-left: -5px;
        margin-right: -5px;
    }

    .container .content-slider--products.content-slider {
        margin-left: 10px;
        margin-right: 10px;
    }

    .content-slider--products .slick-dots{
        bottom: 0;
        margin-top: 20px;
        position: relative;
    }

    .content-slider--products .slick-thumb-arrow{
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        font-size: 16px;
        margin-top: -25px;
        color: var(--color-green);
    }
    .content-slider--products .slick-thumb-arrow.slick-thumb-arrow--next{
        right: -20px;
    }

    .content-slider--products .slick-thumb-arrow.slick-thumb-arrow--prev{
        left: -20px;
    }
}

@media screen and (min-width: 768px){
    .content-slider .slick-thumb-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        color: var(--color-green);
        cursor: pointer;
    }

    .content-slider .slick-thumb-arrow:hover{
        color: #169e29;
    }

    .content-slider .slick-thumb-arrow--prev {
        left: -30px;
    }

    .content-slider .slick-thumb-arrow--next {
        right: -30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1560px){
    .content-slider{
        margin: 0 30px;
    }
}


/* content-slider with product-grid */
.content-slider__item .product-item:hover:before,
.slider-type-grid-product .slick-slide:hover + .slick-slide .product-item:after{
    /*display: none;*/
}
.slider-type-grid-product .content-slider__item .product-item {
    margin-bottom: 0;
}
.slider-type-grid-product .content-slider__item {
    /*border-left: 1px solid #e2e2e8;*/
}
.slider-type-grid-product .content-slider__item:hover + .content-slider__item {
    /*border-left: 1px solid transparent;*/
}

.slider-type-grid-product .content-slider__item .product-item:before {
    left: 0px;
}

.slider-type-grid-product .content-slider__item .product-item:after {
    right: -1px;
}


.slider-type-grid-product .content-slider__item:hover + .content-slider__item .product-item:before {
    display: none;
}

.slider-type-grid-product .content-slider__item {
    position: relative;
}

.slider-type-grid-product .slick-list {
    /*border-right: 1px solid #e2e2e8;*/
}

@media screen and (max-width: 767px) {
    .slider-type-grid-product .content-slider__item .product-item__body {
        padding: 0 10px;
    }

    .slider-type-grid-product .content-slider__item .em-holder {
        position: relative;
    }

}