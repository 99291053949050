
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
	z-index: 10;
}
.noUi-state-tap .noUi-origin {
-webkit-transition: left 0.3s, top 0.3s;
	transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 3px;
}
.noUi-horizontal .noUi-handle {
	width: 18px;
	height: 18px;
	left: -9px;
	top: -9px;
	cursor: pointer;
}
.noUi-vertical {
	width: 18px;
}







/* Styling;
 */
.noUi-background {
	background: #dcdedf;
	/*background: #118516;*/
}
.noUi-connect {
	background: #3FB8AF;
	box-shadow: inset 0 0 3px rgba(51,51,51,0.45);
-webkit-transition: background 450ms;
	transition: background 450ms;
}

.noUi-base .noUi-origin:first-of-type {
	background: #118516;
}
.noUi-base .noUi-origin:last-of-type {
	background: #dcdedf;
}

.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;
 */
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
	border-radius: 50%;
	background: #118516;
	transition: background 0.2s;
	border: 1px solid transparent;
	cursor: default;
}
.noUi-active {
	background: #fff028;
	border: 1px solid #118516;
	transition: background 0.1s;
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed;
}


@media screen and (max-width: 767px) {



	.noUi-horizontal .noUi-handle {
		width: 32px;
		height: 32px;
		left: -15px;
		top: -15px;
		cursor: pointer;
	}

	#range-input-from,
	#range-input-to {
		font-size: 13px;
	}

	.noUi-base .noUi-origin:last-of-type,
	.noUi-background {
		background: #2b3133;
	}

}