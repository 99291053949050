@media screen and (min-width: 768px) {
    .container--product-detail > .row ~ .row {
        margin-top: 20px;
    }
}

@media screen and (min-width: 1200px) {
    .container--product-detail > .row ~ .row {
        margin-top: 50px;
    }
}

.container--product-detail .wysiwyg p {
    margin-bottom: 0;
}

.container--product-detail .wysiwyg p + ul {
    margin-top: 10px;
}


/* btn-order-on-demand */
@media screen and (max-width: 767px) {
    .container.container--product-detail {
        padding-left: 10px;
        padding-right: 10px;
    }
    .btn-order-on-demand {
        display: block;
        margin: 10px;
    }
    .btn-order-on-demand > .icon {
        display: block;
        margin-bottom: 5px;
    }
}

@media screen and (min-width: 768px) {
    .btn-order-on-demand {
        float: right;
        margin-top: 20px;
    }
    .btn-order-on-demand > .icon {
        margin-right: 5px;
    }
}

.product-detail__cat{
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 20px;
}

.article-nr {
    color: #9a9a9a;
}

.product-detail-header__sales-unit {
    color: var(--color-dark-grey);
    font-family: var(--font-headline-bold);
    margin-top: 5px;
    font-size: 14px;
}