/*.video-js {*/
    /*//*override inline styles*//*/
    /*width: 100% !important;*/
    /*height: auto !important;*/
/*}*/
.embed-responsive .video-js {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

/* for yt videos*/
.embed-responsive iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.vjs-default-skin.vjs-default-skin .vjs-big-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -80px;
    margin-left: -80px;
    width: 160px;
    height: 160px;
    color: #ffffff;
    border-radius: 50%;
    background: var(--color-primary);
}
.vjs-default-skin.vjs-default-skin .vjs-big-play-button:before {
    text-shadow: none;
    line-height: 160px;
    font-size: 40px;

}
@media screen and (max-width: 767px) {
    .vjs-default-skin.vjs-default-skin .vjs-big-play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -40px;
        margin-left: -40px;
        width: 80px;
        height: 80px;
        color: #ffffff;
        border-radius: 50%;
        background: var(--color-primary);
    }
    .vjs-default-skin.vjs-default-skin .vjs-big-play-button:before {
        line-height: 80px;
        font-size: 25px;
        left: 5px;
    }
}

.vjs-default-skin.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin.vjs-default-skin .vjs-big-play-button:focus {
    background: var(--color-primary);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
}
.vjs-default-skin.vjs-default-skin.vjs-has-started .vjs-control-bar {
    background: #2b3133;
}
.vjs-default-skin.vjs-default-skin .vjs-play-progress,
.vjs-default-skin.vjs-default-skin .vjs-volume-level {
    background: #118516;
}
.vjs-default-skin.vjs-default-skin .vjs-slider-handle:before {
    text-shadow: none;
    color: #3d3d3f;
}
.vjs-default-skin.vjs-default-skin .vjs-load-progress {
    background: #ffffff;
}
.vjs-default-skin.vjs-default-skin .vjs-progress-control {
    font-size: .6em;
}
.vjs-default-skin.vjs-default-skin .vjs-control {
    color: #fff;
}
.vjs-default-skin.vjs-default-skin .vjs-control:focus:before,
.vjs-default-skin.vjs-default-skin .vjs-control:hover:before {
    text-shadow: none;
    opacity: .9;
}
.vjs-default-skin.vjs-default-skin .vjs-volume-bar {
    font-size: .8em;
    background: #ffffff;
    position: relative;
}
.vjs-default-skin.vjs-default-skin .vjs-volume-level {
    height: .73em;
}

.vjs-default-skin .vjs-big-play-button.vjs-big-play-button:before {
    left: 10px;
}



@font-face {
    font-family: VideoJS;
    src: url(/static/fonts/vjs.eot);
    src: url(/static/fonts/vjs.eot?#iefix) format('embedded-opentype'), url(/static/fonts/vjs.woff) format('woff'), url(/static/fonts/vjs.ttf) format('truetype'), url(/static/fonts/vjs.svg#icomoon) format('svg');
    font-weight: 400;
    font-style: normal;
}


.video-js .vjs-control-bar {
    display: none;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3.0em;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7); }

.vjs-has-started .vjs-control-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility 0.1s, opacity 0.1s;
    -moz-transition: visibility 0.1s, opacity 0.1s;
    -o-transition: visibility 0.1s, opacity 0.1s;
    transition: visibility 0.1s, opacity 0.1s; }


/* new */


.vjs-default-skin .vjs-control-bar {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3em;
    background-color: #07141e;
    background-color: rgba(7, 20, 30, .7);
}
.vjs-default-skin.vjs-has-started .vjs-control-bar {
    display: block;
    visibility: visible;
    opacity: 1;
    -webkit-transition: visibility .1s, opacity .1s;
    -moz-transition: visibility .1s, opacity .1s;
    -o-transition: visibility .1s, opacity .1s;
    transition: visibility .1s, opacity .1s;
}
.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    display: block;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 1s, opacity 1s;
    -moz-transition: visibility 1s, opacity 1s;
    -o-transition: visibility 1s, opacity 1s;
    transition: visibility 1s, opacity 1s;
}
.vjs-default-skin.vjs-controls-disabled .vjs-control-bar {
    display: none;
}
.vjs-default-skin.vjs-using-native-controls .vjs-control-bar {
    display: none;
}
.vjs-default-skin.vjs-error .vjs-control-bar {
    display: none;
}
.vjs-audio.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    opacity: 1;
    visibility: visible;
}
@media \0screen {
    .vjs-default-skin.vjs-user-inactive.vjs-playing .vjs-control-bar:before {
        content: ""}
}.vjs-default-skin .vjs-control {
     outline: 0;
     position: relative;
     float: left;
     text-align: center;
     margin: 0;
     padding: 0;
     height: 3em;
     width: 4em;
 }
.vjs-default-skin .vjs-control:before {
    font-family: VideoJS;
    font-size: 1.5em;
    line-height: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
}
.vjs-default-skin .vjs-control:focus:before, .vjs-default-skin .vjs-control:hover:before {
    text-shadow: 0 0 1em #fff;
}
.vjs-default-skin .vjs-control:focus {
}
.vjs-default-skin .vjs-control-text {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.vjs-default-skin .vjs-play-control {
    width: 5em;
    cursor: pointer;
}
.vjs-default-skin .vjs-play-control:before {
    content: "\e001"}
.vjs-default-skin.vjs-playing .vjs-play-control:before {
    content: "\e002"}
.vjs-default-skin .vjs-playback-rate .vjs-playback-rate-value {
    font-size: 1.5em;
    line-height: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .5);
}
.vjs-default-skin .vjs-playback-rate.vjs-menu-button .vjs-menu .vjs-menu-content {
    width: 4em;
    left: -2em;
    list-style: none;
}
.vjs-default-skin .vjs-mute-control, .vjs-default-skin .vjs-volume-menu-button {
    cursor: pointer;
    float: right;
}
.vjs-default-skin .vjs-mute-control:before, .vjs-default-skin .vjs-volume-menu-button:before {
    content: "\e006"}
.vjs-default-skin .vjs-mute-control.vjs-vol-0:before, .vjs-default-skin .vjs-volume-menu-button.vjs-vol-0:before {
    content: "\e003"}
.vjs-default-skin .vjs-mute-control.vjs-vol-1:before, .vjs-default-skin .vjs-volume-menu-button.vjs-vol-1:before {
    content: "\e004"}
.vjs-default-skin .vjs-mute-control.vjs-vol-2:before, .vjs-default-skin .vjs-volume-menu-button.vjs-vol-2:before {
    content: "\e005"}
.vjs-default-skin .vjs-volume-control {
    width: 5em;
    float: right;
}
.vjs-default-skin .vjs-volume-bar {
    width: 5em;
    height: .6em;
    margin: 1.1em auto 0;
}
.vjs-default-skin .vjs-volume-level {
    position: absolute;
    top: 0;
    left: 0;
    height: .5em;
    width: 100%;

}
.vjs-default-skin .vjs-volume-bar .vjs-volume-handle {
    width: .5em;
    height: .5em;
    left: 4.5em;
}
.vjs-default-skin .vjs-volume-handle:before {
    font-size: .9em;
    top: -.2em;
    left: -.2em;
    width: 1em;
    height: 1em;
}
.vjs-default-skin .vjs-volume-menu-button .vjs-menu {
    display: block;
    width: 0;
    height: 0;
    border-top-color: transparent;
}
.vjs-default-skin .vjs-volume-menu-button .vjs-menu .vjs-menu-content {
    height: 0;
    width: 0;
}
.vjs-default-skin .vjs-volume-menu-button:hover .vjs-menu, .vjs-default-skin .vjs-volume-menu-button .vjs-menu.vjs-lock-showing {
    border-top-color: rgba(7, 40, 50, .5);
}
.vjs-default-skin .vjs-volume-menu-button:hover .vjs-menu .vjs-menu-content, .vjs-default-skin .vjs-volume-menu-button .vjs-menu.vjs-lock-showing .vjs-menu-content {
    height: 2.9em;
    width: 10em;
}
.vjs-default-skin .vjs-progress-control {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    font-size: .3em;
    height: 1em;
    top: -1em;
    transition: all .4s;
}
.vjs-default-skin:hover .vjs-progress-control {
    font-size: .9em;
    transition: all .2s;
}
.vjs-default-skin .vjs-progress-holder {
    height: 100%}
.vjs-default-skin .vjs-progress-holder .vjs-play-progress, .vjs-default-skin .vjs-progress-holder .vjs-load-progress, .vjs-default-skin .vjs-progress-holder .vjs-load-progress div {
    position: absolute;
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 0;
    left: 0;
    top: 0;
}

.vjs-default-skin .vjs-load-progress {
    background: #646464;
    background: rgba(255, 255, 255, .2);
}
.vjs-default-skin .vjs-load-progress div {
    background: #787878;
    background: rgba(255, 255, 255, .1);
}
.vjs-default-skin .vjs-seek-handle {
    width: 1.5em;
    height: 100%}
.vjs-default-skin .vjs-seek-handle:before {
    padding-top: .1em;
}
.vjs-default-skin.vjs-live .vjs-time-controls, .vjs-default-skin.vjs-live .vjs-time-divider, .vjs-default-skin.vjs-live .vjs-progress-control {
    display: none;
}
.vjs-default-skin.vjs-live .vjs-live-display {
    display: block;
}
.vjs-default-skin .vjs-live-display {
    display: none;
    font-size: 1em;
    line-height: 3em;
}
.vjs-default-skin .vjs-time-controls {
    font-size: 1em;
    line-height: 3em;
}
.vjs-default-skin .vjs-current-time {
    float: left;
}
.vjs-default-skin .vjs-duration {
    float: left;
}
.vjs-default-skin .vjs-remaining-time {
    display: none;
    float: left;
}
.vjs-time-divider {
    float: left;
    line-height: 3em;
}
.vjs-default-skin .vjs-fullscreen-control {
    width: 3.8em;
    cursor: pointer;
    float: right;
}
.vjs-default-skin .vjs-fullscreen-control:before {
    content: "\e000"}
.vjs-default-skin.vjs-fullscreen .vjs-fullscreen-control:before {
    content: "\e00b"}
.vjs-default-skin .vjs-big-play-button {
    z-index: 2;
    cursor: pointer;
    opacity: 1;
    outline: none;
    transition: all .25s;
}
.vjs-default-skin.vjs-big-play-centered .vjs-big-play-button {
    left: 50%;
    margin-left: -2.1em;
    top: 50%;
    margin-top: -1.4000000000000001em;
}
.vjs-default-skin.vjs-controls-disabled .vjs-big-play-button {
    display: none;
}
.vjs-default-skin.vjs-has-started .vjs-big-play-button {
    display: none;
}
.vjs-default-skin.vjs-paused .vjs-big-play-button {
    display: block;
}
.vjs-default-skin.vjs-using-native-controls .vjs-big-play-button {
    display: none;
}
.vjs-default-skin .vjs-big-play-button:before {
    content: "\E003";
    font-family: "iconfont";
    line-height: 2.6em;
    text-align: center;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%
}
.vjs-error .vjs-big-play-button {
    display: none;
}
.vjs-error-display {
    display: none;
}
.vjs-error .vjs-error-display {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%}
.vjs-error .vjs-error-display:before {
    content: 'X';
    font-family: Arial;
    font-size: 4em;
    color: #666;
    line-height: 1;
    text-shadow: .05em .05em .1em #000;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -.5em;
    width: 100%}
.vjs-error-display div {
    position: absolute;
    bottom: 1em;
    right: 0;
    left: 0;
    font-size: 1.4em;
    text-align: center;
    padding: 3px;
    background: #000;
    background: rgba(0, 0, 0, .5);
}
.vjs-error-display a, .vjs-error-display a:visited {
    color: #F4A460;
}


.vjs-default-skin .vjs-menu-button {
        float: right;
        cursor: pointer;
    }
.vjs-default-skin .vjs-menu {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    margin-bottom: 3em;
    border-left: 2em solid transparent;
    border-right: 2em solid transparent;
    border-top: 1.55em solid #000;
    border-top-color: rgba(7, 40, 50, .5);
}

.vjs-default-skin .vjs-menu-button:hover .vjs-control-content .vjs-menu, .vjs-default-skin .vjs-control-content .vjs-menu.vjs-lock-showing {
    display: block;
}
.vjs-default-skin.vjs-scrubbing .vjs-menu-button:hover .vjs-control-content .vjs-menu {
    display: none;
}
.vjs-default-skin .vjs-menu-button ul li {
    list-style: none;
    margin: 0;
    padding: .3em 0;
    line-height: 1.4em;
    font-size: 1.2em;
    text-align: center;
    text-transform: lowercase;
}

.vjs-default-skin .vjs-subtitles-button:before {
    content: "\e00c"}
.vjs-default-skin .vjs-captions-button:before {
    content: "\e008"}
.vjs-default-skin .vjs-chapters-button:before {
    content: "\e00c"}
.vjs-default-skin .vjs-chapters-button.vjs-menu-button .vjs-menu .vjs-menu-content {
    width: 24em;
    left: -12em;
}
.vjs-default-skin .vjs-captions-button:focus .vjs-control-content:before, .vjs-default-skin .vjs-captions-button:hover .vjs-control-content:before {
    -webkit-box-shadow: 0 0 1em #fff;
    -moz-box-shadow: 0 0 1em #fff;
    box-shadow: 0 0 1em #fff;
}
.video-js {
    background-color: #000;
    position: relative;
    padding: 0;
    font-size: 10px;
    vertical-align: middle;
    font-weight: 400;
    font-style: normal;
    font-family: Arial, sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.video-js .vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%}
.video-js:-moz-full-screen {
    position: absolute;
}
body.vjs-full-window {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-y: auto;
}
.video-js.vjs-fullscreen {
    position: fixed;
    overflow: hidden;
    z-index: 1000;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%!important;
    height: 100%!important;
    _position: absolute;
}
.video-js:-webkit-full-screen {
    width: 100%!important;
    height: 100%!important;
}
.video-js.vjs-fullscreen.vjs-user-inactive {
    cursor: none;
}
.vjs-poster {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.vjs-poster img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    padding: 0;
    width: 100%}








