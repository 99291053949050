
.popover--small {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding: 1px;
    /*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);

    line-break: auto;
}
.popover--small.top {
    margin-top: -10px;
}
.popover--small.right {
    margin-left: 10px;
}
.popover--small.bottom {
    margin-top: 10px;
}
.popover--small.left {
    margin-left: -10px;
}
.popover--small-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}
.popover--small-content {
    padding: 9px 14px;
}
.popover--small > .arrow,
.popover--small > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.popover--small > .arrow {
    border-width: 11px;
}
.popover--small > .arrow:after {
    content: "";
    border-width: 10px;
}
.popover--small.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, .25);
    border-bottom-width: 0;
}
.popover--small.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #fff;
    border-bottom-width: 0;
}
.popover--small.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, .25);
    border-left-width: 0;
}
.popover--small.right > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #fff;
    border-left-width: 0;
}
.popover--small.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, .25);
}
.popover--small.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff;
}
.popover--small.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, .25);
}
.popover--small.left > .arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #fff;
}

/* lagerhaus styles*/
.popover--small.popover--green {
    border-radius: 0;
    border: 1px solid var(--color-green);
    color: var(--color-green);
    font-weight: bold;
    width: 170px;
    font-size: 13px;
    box-shadow: 0 0px 10px rgba(0, 0, 0, .2);
}
.popover--small.popover--green .popover-content {
    padding: 10px;
    padding-left: 12px;
}

.popover--small.popover--green.left .arrow {
    border-left-color:  var(--color-green);
}

.popover--small.popover--green.left > .arrow:after {
    border-left-color:  var(--color-green);
}

.popover--small.popover--green.bottom > .arrow {
    border-bottom-color: var(--color-green);
}
.popover--small.popover--green.bottom > .arrow:after {
    border-bottom-color: var(--color-green);
}

.popover--small.popover--green:before {
    content: "\E02E"; /* icon-info */
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);


    color: var(--color-green);
    width: 25px;
    height: 25px;
    background: var(--color-bg-third);
    padding-top: 5px;
    text-align: center;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: -10px;
    top: -10px;
    box-shadow: 0 0 7px rgba(0,0,0,.4);
    font-size: 13px;
}