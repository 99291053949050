section.fuel-price-chart{
    margin-bottom: 50px;
}
svg.ct-chart-line{
    overflow: visible;
}

line.ct-grid.ct-horizontal{
    display:none;
}

line.ct-grid.ct-vertical{
    stroke: rgba(0,0,0,.6);
    stroke-width: 0.5;
    stroke-dasharray: 0px;
}

text.ct-label{
    fill: #2b3133;
}

.ct-label.ct-vertical.ct-start {
    font-size: 14px;
}

span.chartist-tooltip-meta, span.chartist-tooltip-value{
    text-align: center;
    display: block;
    font-size: 13px;
    color: #2b3133;
}

line.ct-point {
    opacity: 0;
    stroke-width: 12px;
}
.ct-point:hover {
    opacity: 1;
}

.price-eur{
    font-size: 13px;
    font-weight: 700;
    left: 20px;
    margin-bottom: 15px;
}

/*label bottom*/
.ct-label.ct-horizontal.ct-end{
    color: #2b3133;
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    height: 25px;
    position: relative;
    padding-top: 10px;
    left: -30px;
    z-index: 0;
}

.chart-showline-buttons{
    text-align: right;
}


@media screen and (max-width: 768px) {
    .chart-showline-buttons li{
        padding: 0;
    }
    .chart-showline-buttons{
        text-align: center;
    }

}
@media screen and (max-width: 1300px){
    g.ct-labels foreignObject:nth-child(even) span.ct-label.ct-horizontal.ct-end{
        top: 20px;
    }
    g.ct-labels foreignObject:nth-child(even) .ct-label__item:before{
        height: 34px;
        top: -25px;
    }
    .chart-showline-buttons label{
        padding: 5px 3px 5px 33px;
    }
}

/*label bottom - line*/

.ct-label__item:before{
    content: "";
    border-left: 2px solid #d5d5d5;
    height: 12px;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.ct-label__item{
    width: 8.33333333%;
}

/*label left*/
span.ct-label.ct-vertical.ct-start{
    color: #2b3133;
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 0;
}

/*checkbox*/
.chart-showline-buttons .checkbox-wrap label:before{
    left: 7px;
    top: 5px;
}

/*tic*/
.chart-showline-buttons .checkbox-wrap input[type=checkbox]+label:after{
    color: #585d5f;
    left: 10px;
    top: 2px;
}

.chartist-tooltip{
    position: absolute;
    display: none;
    min-width: 100px;
    color: #0d0d0d;
    padding: 6px 6px 4px 6px;
    background: #ffffff;
    margin-left: 10px;
    margin-top: 4px;
}

.chartist-tooltip.tooltip-show{
    display: block;
}

/*tooltip inner triangle*/
.chartist-tooltip.tooltip-show:after{
    content: "";
    top: calc(100% - 2px);
    left: 50%;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 10px solid #ffffff;
    position: absolute;
    margin-left: -12px;
}

/*outline whole tooltip*/
.chartist-tooltip--outline:after{
    content: "";
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -15px;
}

.chartist-tooltip--outline {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
}

@-moz-document url-prefix() {

}

/*---------------- COLORS -----------------*/
:root{
    --ct-series-a: #f6cf1c;
    --ct-series-b: #e39339;
    --ct-series-c: #821900;
    --ct-series-d: #990099;
    --ct-series-e: var(--color-green);
    --ct-series-f: #0000cc;
}

/*lines*/
.ct-series-a path.ct-line, .ct-series-a line.ct-point{
    stroke: var(--ct-series-a);
}
.ct-series-b path.ct-line, .ct-series-b line.ct-point{
    stroke: var(--ct-series-b);
}
.ct-series-c path.ct-line, .ct-series-c line.ct-point{
    stroke: var(--ct-series-c);
}
.ct-series-d path.ct-line, .ct-series-d line.ct-point{
    stroke: var(--ct-series-d);
}
.ct-series-e path.ct-line, .ct-series-e line.ct-point{
    stroke: var(--ct-series-e);
}
.ct-series-f path.ct-line, .ct-series-f line.ct-point{
    stroke: var(--ct-series-f);
}

/*checkboxes*/
.checkbox--outline.ct-series-a{
    border: 2px solid var(--ct-series-a);
}
.checkbox--outline.ct-series-b{
    border: 2px solid var(--ct-series-b);
}
.checkbox--outline.ct-series-c{
    border: 2px solid var(--ct-series-c);
}
.checkbox--outline.ct-series-d{
    border: 2px solid var(--ct-series-d);
}
.checkbox--outline.ct-series-e{
    border: 2px solid var(--ct-series-e);
}
.checkbox--outline.ct-series-f{
    border: 2px solid var(--ct-series-f);
}
.chart-showline-buttons label{
    padding: 5px 5px 5px 35px;
}

/*border tooltip square*/
.chartist-tooltip--outline.ct-series-a{
    border: 2px solid var(--ct-series-a);
}
.chartist-tooltip--outline.ct-series-b{
    border: 2px solid var(--ct-series-b);
}
.chartist-tooltip--outline.ct-series-c{
    border: 2px solid var(--ct-series-c);
}
.chartist-tooltip--outline.ct-series-d{
    border: 2px solid var(--ct-series-d);
}
.chartist-tooltip--outline.ct-series-e{
    border: 2px solid var(--ct-series-e);
}
.chartist-tooltip--outline.ct-series-f{
    border: 2px solid var(--ct-series-f);
}

/*border tooltip triangle*/
.chartist-tooltip--outline.ct-series-a:after{
    border-top: 13px solid var(--ct-series-a);
}
.chartist-tooltip--outline.ct-series-b:after{
    border-top: 13px solid var(--ct-series-b);
}
.chartist-tooltip--outline.ct-series-c:after{
    border-top: 13px solid var(--ct-series-c);
}
.chartist-tooltip--outline.ct-series-d:after{
    border-top: 13px solid var(--ct-series-d);
}
.chartist-tooltip--outline.ct-series-e:after{
    border-top: 13px solid var(--ct-series-e);
}
.chartist-tooltip--outline.ct-series-f:after{
    border-top: 13px solid var(--ct-series-f);
}

.ct-labels{
    position: relative;
}

.ct-labels.ct-labels foreignObject>.ct-horizontal {
    width: 60px !important; /*overwrite inline style*/
    text-align: center !important;
    display: block;
}