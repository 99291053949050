.social-icon {
    display: block;
    height: 40px;
    width: 40px;
    font-size: 40px;
    line-height: 40px;
    margin: 0 5px;
    float: left;
}
.social-icon.social-icon--facebook { color: #075d7d; }
.social-icon.social-icon--googleplus { color: #831900; }
.social-icon.social-icon--twitter { color: #0094d3; }

    
#btn-scrollTop {
    background: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 11px;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
    #btn-scrollTop {
        position: fixed;
        right:10px;
        bottom:13px;
        z-index: 500;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.35);
    }
}

