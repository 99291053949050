.reservation-top {
    padding-bottom: 10px;
}
@media screen and (min-width: 786px){
    .reservation-top {
        padding: 30px 0 20px;
        border-top:1px solid #d9d9e1;
    }
}
.reservation-address{
    font-size: 14px;
    line-height:calc(28/18);
    margin-bottom: 0;
}
@media screen and (min-width: 786px){
    .reservation-address{
        font-size: 18px;
    }
}
.reservation-availability-info {
    font-size: 20px;
}
.reservation-availability-info__circle {
    font-size: 15px;
}


/*price table*/
.price-table {
    font-size: 14px;
    line-height:calc(17/14);
    padding: 10px 5px;
}
@media screen and (min-width: 768px) {
    .price-table {
        font-size: 16px;
        line-height:calc(18/16);
        padding: 22px 5px;
    }
}
.price-table__head {
    font-family:var(--font-headline-bold);
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
}
.price-table__row {
    padding: 6px 0;
    border-top: 1px solid #9c9fa3;
}
@media screen and (min-width: 768px) {
    .price-table__row {
        padding: 10px 0;
        border: 0;
    }
}
.price-table__seperator {
    background-color: #9c9fa3;
    height: 1px;
    margin: 6px 0;
}
.price-table__total {
    margin-top: 5px;
}
@media screen and (min-width: 768px) {
    .price-table__total {
        margin-top: 15px;
    }
}