.filter-box{
    margin-bottom: 30px;
}

.filter-box__body{
   background: #118516;
    color: #fff;
}

.filter-box__body,
.filter-box__bottom{
    padding: 20px;
}

.btn-filter--dark {
    color: var(--color-green);
    font-family:var(--font-headline-bold);
}

@media screen and (min-width: 768px){

    .filter-box{
        margin-bottom: 70px;
    }
    .filter-box__body{
        padding: 30px 30px 20px 30px;
        background: linear-gradient(180deg, #169e29 0%, #118516 100%);
    }
    .filter-box__bottom{
        padding: 13px 20px;
    }
}

.filter-box label{
    font-size: 13px;
    font-family: Arial;
}

.filter-box label.label-big,
.filter-box .label-big{
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 5px;
}

.filter-box .checkbox label{
    font-size: 18px;
}

.filter-box .noUi-base{
    background: #d8d8d8;
}

.filter-box .noUi-base .noUi-origin:first-of-type,
.filter-box .noUi-handle{
    background: #074d08;
}

.filter-box__checkbox{
    margin-top: 23px;
}

.filter-box .filter-element{
    padding: 5px 0;
}

.filter-box .filter-box__anzahl{
    font-size: 20px;
}

.filter-box__machine-text{
    color: #939699;
    font-size: 14px;
    margin-left: 5px;
    position: relative;
    top: -2px;
}

@media screen and (min-width: 768px){
    .filter-box__machine-text{
        font-size: 20px;
        margin-left: 12px;
        top:-5px;
    }

    .filter-box .filter-box__anzahl{
        font-size: 38px;
    }

    .filter-box__checkbox .form-group{
        margin-right: 20px;
        display: inline-block;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 767px){
    .filter-box__bottom .btn{
        margin-top: 10px;
        font-size: 18px;
        white-space: normal;
    }

    .btn-filter-products{
        background: #118516;
        text-align: left;
        position: relative;
        padding: 15px 20px;
    }

    .btn-filter-products .icon{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 7px;
    }

    .btn-filter-products.is-open .icon{
        transform: rotate(180deg);
    }

    .filter-box__body{
        display: none;
        padding-top: 0;
    }

    .btn-filter{
        display: none;
    }

    .btn-filter.is-open{
        display: block;
    }

    .dropdown-xs .dropdown-toggler--orderProducts{
        display: block;
        text-align: left;
        position: relative;
        background-color: #f5f5fa;
        font-size: 16px;
        margin-bottom: 20px;
        padding: 14px 15px;
    }

    .dropdown-xs .dropdown-toggler--orderProducts .icon{
        position: absolute;
        right: 14px;
        font-size: 7px;
        top: 50%;
        transform: translateY(-50%);
    }

    .dropdown-xs .dropdown-menu-right{
        left:0;
    }

    .dropdown-xs .dropdown-menu-right.dropdown-menu>li>a{
        font-size: 16px;
        padding: 10px 20px;
    }
}