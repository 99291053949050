.nav--footer-links > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* kontak-nav */
#nav--footer-contact {
    line-height: 1;
}

#nav--footer-contact ul li > a {
    padding: 10px 0;
}


@media screen and (max-width: 767px) {
    #nav--footer-contact .icon {
        font-size: 27px;
        margin-right: 15px;
    }
    #nav--footer-contact ul {
        margin: 0;
    }
    #nav--footer-contact ul li {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
    }

    #nav--footer-contact ul li > a {
        padding: 15px 5px;
        background: var(--color-bg-primary);
    }
    #nav--footer-contact ul li > a article span + span {
        margin-top: 3px;
    }
}

@media screen and (min-width: 768px) {

    #nav--footer-contact .icon {
        font-size: 33px;
        margin-right: 15px;
        min-width: 50px;
    }

    #nav--footer-contact ul li + li > a {
        border-top: 1px solid #eaeaef;
    }
    #nav--footer-contact ul li:not(:last-of-type) > a {
        border-bottom: 1px solid #f8f8f9;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    #nav--footer-contact ul li > a {
        padding: 15px 0;
    }
}