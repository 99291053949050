.basement45 {
    border: 1px solid red;
}


/*house slider*/
.house-slider-wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
}
@media screen and (min-width: 768px) {
    .house-slider-wrapper {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}
.house-slider .slick-slider {
    margin-bottom: 0;
}
.house__detail {
    background: #fff;
    overflow: hidden;
}
@media screen and (min-width: 768px) {
    .house__detail {
        box-shadow: 0 2px 4px rgba(0,0,0,.3);
        padding: 20px;
    }
}
.house .tab-content {
    padding: 0;
}
@media screen and (min-width: 768px) {
    .house__image-thumbs {
        position: absolute;
        right: 10px;
        top:10px;
        width: 40px;
        z-index: 1;
    }
    .house__image-thumbs li {
        margin-bottom: 5px;
        border: 1px solid #fff;
    }
}

.house-slider__thumbs{
    padding: 0 47px;
    margin-left: -.5px;
    margin-right: -.5px;
}
.house-slider__thumbs__item {
    padding: 0 .5px;
}
@media screen and (min-width: 768px) {
    .house-slider__thumbs {
        padding: 0;
        margin-top: 10px;
        margin-left: -5px;
        margin-right: -5px;
    }
    .house-slider__thumbs__item {
        padding: 0 5px;
    }
}
@media screen and (max-width: 767px) {
    .house-slider__thumbs .slick-arrow {
        width: 47px;
        height: auto;
        top: 0;
        bottom: 0;
        transform: none;
        background: #ebebef;
    }
    .house-slider__thumbs .slick-arrow:before {
        font-size: 10px;
    }
}
.house-slider__thumbs__item__title {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: var(--color-bg-third);
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    padding: 5px;
}
.house-slider__thumbs__item:hover .house-slider__thumbs__item__title {
    opacity: 1;
}
.house__detail-plan {
    border: 1px solid #ccc;
    padding-top: 5px;
    background: #ffffff;
}
.house__detail-plan__floor {
    margin-top: 5px;
    display: block;
    background: #fff;
    padding: 0 5px;
    color: #a0a0a0;
}
.house-slider .slick-arrow {
    background: var(--color-bg-third);
    width: 40px;
    height: 40px;
    z-index: 1;

    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}
.house-slider .slick-arrow:before {
    content: "\E002";
    color: var(--color-primary);
    opacity: 1;
    font-size: 16px;
}
.house-slider .slick-next:before {
    content: "\E003";
}
.house-slider .slick-prev {
    left: 0;
}
.house-slider .slick-next {
    right: 0;
}
.house__plan {
    z-index: 1;
    padding: 20px 50px 20px 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}
@media screen and (min-width: 768px) {
    .house__plan {
        padding: 20px;
    }
}
.house__plan.is-active {
    visibility: visible;
    opacity: 1;
}
.house__plan__close {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--color-bg-third);
    width: 40px;
    height: 40px;
    padding: 12px;
}
@media screen and (min-width: 768px) {
    .house__plan__close {
        top: 20px;
        right: 20px;
    }
}
.house__plan__detail ul {
    padding-left: 15px;
}
.house__plan__detail h3 {
    font-family:var(--font-headline-default);
    font-size: 14px;
}
.house .panel-title a{
    position: relative;
    padding-right: 30px;
}
.house .panel-title__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    transition: transform 250ms ease-in-out;
}
.house .collapsed .panel-title__icon {
    transform: translateY(-50%) rotate(0);
}

.house__detail__panel .panel-heading {
    margin-bottom: 2px;
}
.house__detail__panel .panel-body {
    position: relative;
}
.house__detail__hl {
    color: var(--color-green);
}