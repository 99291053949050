.checkout-box {
    padding: 15px;
}
.checkout-box--lg {
    margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
    .checkout-box {
        padding: 25px 30px;
    }
    .checkout-box--lg {
        margin-bottom: 36px;
    }
}
.sidebar-checkout .checkout-box.is-active {
    color: var(--color-primary);
}
.checkout-box__title {
    position: relative;
    padding-right: 20px;
    display: block;
}
.checkout-box--lg .checkout-box__title {
    font-family:var(--font-headline-bold);
    font-weight: 400;
    font-size: 18px;
}
@media screen and (min-width: 768px) {
    .checkout-box--lg .checkout-box__title {
        font-size: 20px;
    }
}
.checkout-box__title__icon {
    position: absolute;
    right: 0;
    top: 0;
    color: var(--color-green);
}
.checkout-box--lg .checkout-box__title__icon {
    top: 5px;
    font-size: 16px;
}
.sidebar-checkout .checkout-box {
    color: #9c9fa3;
}
.sidebar-checkout .is-active .checkout-box__title__icon,
.sidebar-checkout a.checkout-box__title:hover {
    color: var(--color-green);
}
.sidebar-checkout .checkout-box + .checkout-box {
    border-top: 3px solid #fff;
}
.checkout-box--lg .checkout-box__val {
    margin-top: 5px;
    /*font-size: 16px;*/
}
@media screen and (min-width: 768px) {
    .checkout-box--lg .checkout-box__val {
        margin-top: 10px;
        /*font-size: 18px;
        line-height:calc(28/18);*/
    }
}
.checkout-box__val address {
    /*line-height:calc(28/18);*/
    margin-bottom: 10px;
}
.checkout-box__btn[disabled]{
    cursor: default;
    opacity: 1;
    background-image: linear-gradient(180deg, #b9b9c7 0%, #9d9db0 100%);
}

.checkbox-wrap a {
    text-decoration: underline;
}