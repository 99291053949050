.er-holder {
    position: relative;
}

.er-holder--gradient {
    background-image: linear-gradient(to top, white 0%, #f5f5fa 100%);
}
.teaser__footer {
    flex-grow: 1;
    padding: 10px;
    position: relative;
    background-image: var(--color-green);
}

.teaser__footer:after {
    content: '';
    position: absolute;
    right: 0;
    bottom:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 18px 18px;
    border-color: transparent transparent var(--color-green) transparent;
}
@media screen and (min-width: 768px){
    .teaser__footer:after {
        border-width: 0 0 33px 33px;
    }
}

.row--same-height .teaser {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.row--same-height .teaser footer {
    flex-grow: 1;
}

/* footer-slogan */
.teaser__footer__slogan {
    position: absolute;
    bottom: 100%;
    left: 0;
    padding:10px 10px 10px 15px;
    font-family: inherit;
    line-height: 1;
}

@media screen and (max-width: 767px) {
    .teaser__footer {
        padding: 10px;
        line-height: 1.3;
    }
    .teaser__footer__slogan {
        padding:10px;
    }
    .has-four-teasers .col:nth-of-type(3),
    .has-three-teasers .col:nth-of-type(3),
    .has-four-teasers .col:nth-of-type(4) {
        margin-top: 10px;
    }
    .col-xs-6 .teaser__footer {
        font-size: 12px;
    }
}



@media screen and (min-width: 992px) {

   .teaser footer:after {
       transition: border-width 0.6s ease;
   }
    .teaser:hover footer:after {
        border-width: 0 0 50px 50px;
        transition: border-width 0.2s ease;
    }

}


@media screen and (min-width: 1200px) {
    .teaser__footer {
        padding: 15px;
    }
}

/* HERO-TEASER
 -------------------- */

/* promotion-label */
.teaser__promotion {
    position: absolute;
    left: 0;
    top: 15px;
    padding: 5px;
    height: 20px;
    color: #fff;
    background: var(--color-green);
    line-height: 1;
    white-space: nowrap;
}
.teaser__promotion--brown-uppercase {
    text-transform: uppercase;
    background: #831900;
}
.teaser__promotion--discount {
    background: var(--color-discount);
    color: var(--color-discount-secondary);
    text-transform: uppercase;
}
.teaser__promotion--bulk-discount {
    background: var(--color-discount-secondary);
    color: var(--color-discount);
}
.teaser__promotion:after {
    content:'';
    position: absolute;
    right: -7px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 7px;
    border-color: transparent;
}
.teaser__promotion.teaser__promotion--brown-uppercase:after {
    border-color: transparent transparent transparent #831900;
}
.teaser__promotion--discount:after {
    border-color: transparent transparent transparent var(--color-discount);
}
.teaser__promotion--bulk-discount:after {
    border-color: transparent transparent transparent var(--color-discount-secondary);
}

@media screen and (min-width: 992px) {
    .teaser__promotion {
        top: 20px;
        padding: 7px;
        height: 24px;
    }

    .teaser__promotion:after {
        border-width: 12px 0 12px 7px;
    }
}

@media screen and (min-width: 1200px) {
    .teaser__promotion {
        top: 30px;
        padding: 8px;
        height: 30px;
    }

    .teaser__promotion:after {
        border-width: 15px 0 15px 7px;
    }
}



/* discount-label */
.teaser__discount {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    text-align: center;
    font-family: 'interstate_regular', serif;
    font-style: italic;
}

/* price-wrapper */
.teaser .price-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 50%;
}
.price-wrapper__brandlogo {
    width: 50px;
    height: 50px;
    margin: 0 auto 5px 15px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
}

.teaser .price-wrapper__price-old {
    display: block;
    padding: 5px 5px 5px 14px;
    background: rgba(255,255,255,0.4);
}

.price-wrapper__price-current-normal,
.product-item .price-wrapper__price-current-normal {
    display: inline-block;
    font-family: 'interstate_regular', serif;
    padding: 5px 28px 5px 0;
}

.price-wrapper__price-current-normal sub {
    display: inline-block;
    font-size: 65%;
    font-family: 'interstate_regular', serif;
}

.price-wrapper__price-current {
    display: inline-block;
    color: #a10000;
    padding: 5px 14px 5px 14px;
    font-family: 'interstate_regular', serif;
}
.price-wrapper--discount .price-wrapper__price-current {
    background: var(--color-discount);
    color: var(--color-discount-secondary);
}
.price-wrapper--bulk-discount .price-wrapper__price-current {
    background: var(--color-discount-secondary);
    color: var(--color-discount);
}
.price-wrapper__price-current sup,
.price-wrapper__price-current-normal sup {
    font-size: 60%;
}

.price-wrapper__price-old,
.price-wrapper__price-current,
.price-wrapper__price-current-normal,
.price-wrapper__price-per-unit{
    white-space: nowrap;
    line-height: 1;
}

.price-wrapper__price-current .price-wrapper__price-per-unit {
    color: inherit;
}

.price-wrapper__price-per-unit {
    color: var(--color-dark-grey);
}


@media screen and (min-width: 1200px) {

    .teaser .price-wrapper__price-old {
        padding: 5px 5px 8px 20px;
    }
    .price-wrapper__price-current {
        padding: 8px 20px 5px 20px;
    }
    .price-wrapper__price-current-normal {
        padding: 5px 23px 5px 5px;
    }

}
