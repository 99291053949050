/* Category-Navigation
 --------------------------------- */
.filter-wrapper .cat-nav-wrapper {
    padding-top: 5px;
}

.nav-category ul li a {
    font-family: 'museo_500';

}

@media screen and (min-width: 768px) {

    .show-all-cat {
        font-family: 'museo_700';
        font-size: 17px;
        padding:0 10px 10px 10px;
        display: block;
        margin-bottom: 10px;
        border-bottom: 1px solid #e2e2e8;
    }

    .nav-category {
        margin-bottom: 50px;
    }
    
    .nav-category ul li a {
        padding: 10px;
        font-size: 20px;
        line-height: 1;
        display: block;
        position: relative;
    }
    .nav-category ul li a small {
        font-size: 11px;
        margin-left: 3px;
        font-family: Arial;
    }
}

@media screen and (min-width: 992px) {

    .nav-category ul li a {
        padding-right: 25px;
    }
    .nav-category ul li:hover a {
        background-color: #f5f5fa;
    }
    .nav-category ul li a:after {
        content: "\E003";
        font-family: iconfont;
        font-size: 10px;
        position: absolute;
        right: 25px;
        top: 50%;
        opacity: 0;
        transition: all .25s ease;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    .nav-category ul li a:hover:after,
    .nav-category ul li.active a:after {
        right: 10px;
        opacity: 1;
        transition: all .2s ease;
    }
}

/* left arrow for all-categories-item*/
.nav-category li.all-categories > a:after,
.nav-category li.all-categories > a:hover:after {
    content: "\E003";
    font-family: iconfont;
    font-size: 10px;
    position: absolute;
    top: 50%;
    opacity: 1;
    left: 5px;
    right: auto;
    transform: translateY(-50%) rotate(180deg);
}

@media screen and (min-width: 768px) {
    .nav-category li.all-categories > a {
        padding-left: 20px;
    }
}

/* HEADLINES ::
 --------------------------------- */
@media screen and (min-width: 768px) {

    .filter-headline {
        padding: 10px;
        font-family: 'museo_500';
        font-weight: normal;
       /* margin-bottom: 15px;*/
    }

}

@media screen and (min-width: 1200px) {

    .filter-headline {
        padding: 15px;
    }

    .nav-category ul li a {
        padding-right: 30px;
    }

}



/* FILTER ELEMENTS ::
 --------------------------------- */
.filter-element {
    padding: 10px 0;
   /* border-top: 1px solid #e2e2e8;*/
}

.filter-element header {
    margin-bottom: 10px;
}

.filter-element header .filter-element__clear-filter {
    cursor: pointer;
    padding: 5px 0 5px 5px;
}

@media screen and (min-width: 768px) {

     .filter-wrapper {
         padding-right: 10px;
     }
    .filter-element {
        padding: 10px 0;
    }
    #product-filter .filter-element {
        padding: 20px 10px;
    }
    #product-filter .filter-element ~ .filter-element {
        border-top: 1px solid #e2e2e8;
    }
    .filter-element header .filter-element__clear-filter {
        margin-right: -10px;
    }
    .filter-box .filter-box__item {
        margin: 15px 0;
    }
}

@media screen and (min-width: 1200px) {
    .filter-wrapper {
        padding-right: 20px;
    }
    .filter-element {
        padding: 15px 0;
    }
    .filter-element header {
        margin-bottom: 20px;
    }

    #product-filter .filter-element {
        padding: 20px 10px;
    }

}

/* range-slider
 --------------------------------- */
.range-slider {
    margin-bottom: 20px;
}


/* XS - FILTERS
 --------------------------------- */
@media screen and (max-width: 768px) {

    #productgrid-container { margin-top: 0; }

    #xs-filter-toggleContent {
        display: none;
    }

    .range-slider {
        margin-top: 20px;
        margin-left: 14px;
        margin-right: 14px;
    }

    .filter-box .filter-box__item .checkbox-wrap {
        margin: 0;
    }
    .filter-box .filter-box__item .checkbox-wrap label {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 25px;
        display: block;
    }
    .filter-box .filter-box__item .checkbox-wrap label:before {
        top: 8px;
    }
    .filter-box .filter-box__item .checkbox-wrap label:after {
        top: 5px;
    }

}


.show-more-filters {
    margin-top: 10px;
    display: block;
    color: #787878;

}

.show-more-filters>span.icon{
    font-size: 10px;
    margin-right: 7px;
}






@media screen and (max-width: 767px) {

    /* xs-categories
    ---------------- */
    #xs-show-cats {
        text-align: left;
        position: relative;
    }
    
    #xs-show-cats span.icon{
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 8px;
    }
    #xs-show-cats.is-open span.icon{
        transform: rotate(180deg);
    }
    
    /* xs-cat-nav */
    .cat-nav-wrapper {
        padding: 0;
        margin: 0;
        position: relative;
    }
    .nav-category {
        display: none;
        padding: 10px;
        background: #ebebef;
        border-top: 1px solid white;
        color: black;
        position: absolute;
        top: 100%;
        z-index: 200;
        width: 100%;
        font-size: 16px;
    }
    #xs-show-cats.is-open + .nav-category {
        display: block;
    }
    .nav-category > ul li a {
        padding: 7px 0;
        display: block;
    }
    .nav-category ul li:not(:last-of-type) a {
        border-bottom: 1px solid white;
    }

    .nav-category .all-categories a{
        position: relative;
        font-family: 'museo_700';
        padding-left: 20px;
        font-size: 16px;
    }

    .nav-category ul li.level-1-category a,
    .nav-category ul li.level-2-category a,
    .nav-category ul li.level-3-category a,
    .nav-category ul li.sub-link.level-1-category a,
    .nav-category ul li.sub-link.level-2-category a,
    .nav-category ul li.sub-link.level-3-category a {
        padding-left: 0;
    }

    .nav-category ul li.level-1-category {
        margin-left: 20px;
    }

    .nav-category ul li.sub-link.level-1-category {
        margin-left: 35px;
    }

    .nav-category ul li.level-2-category {
        margin-left: 35px;
    }

    .nav-category ul li.sub-link.level-2-category {
        margin-left: 50px;
    }

    .nav-category ul li.level-3-category {
        margin-left: 50px;
    }

    .nav-category ul li.sub-link.level-3-category {
        margin-left: 65px;
    }

        /* xs-filter-- select */
    .styled-select {
        margin-bottom: 10px;
    }
    .styled-select .icon {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 8px;
    }
    .styled-select select {
        width: 100%;
        height: 40px;
        border: none;
        padding: 8px 15px;
        font-size: 16px;
        outline: none;
        border-radius: 0;
        -webkit-appearance: none;
    }

   .styled-select select:focus + .icon {
        transform: rotate(180deg);
    }



    /* btn-toggle-filters */
    #btn-toggle-filters {
        height: 40px;
    }
    #btn-toggle-filters .icon {
        position: absolute;
        right: 15px;
        top: 15px;
        font-size: 8px;
    }
    #btn-toggle-filters.is-open > .icon{
        transform: rotate(180deg);
    }
    



    #xs-filter-toggleContent{
        display: none;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        background: #ebebef;
        border: 1px solid #2b3133;
    }

    #xs-filter-toggleContent.is-visible{
        display: block;
        margin-top: 7px;
    }

    .filter-element h4{
        font-size: 15px;
    }

    #xs-filter-toggleContent>.filter-element{
        padding: 15px 0;
    }

    #xs-filter-toggleContent > .filter-element + .filter-element {
        border-top: 1px dashed black;
    }

    .filter-element header .filter-element__clear-filter {
        font-size: 12px;
    }

    #xs-filter-toggleContent .filter-box {
        padding: 0;
        column-count: 2;
        column-gap: 15px;
    }

    .mobile-filter-container:not(:empty) {
        padding-top: 5px;
    }
    .filter-wrapper a.mobile-filter-button {
        margin: 2.5px 5px 2.5px 0;
        font-size: 13px;
        background-color: #f05a00;
        color: #fff;
        padding: 3px 12px;
        float: left;
        display: flex !important;
        min-height: 35px;
        align-items: center;
    }

    .filter-wrapper a.mobile-filter-button span.icon{
        font-size: 10px;
        margin-right: 5px;
    }





}


