.fuel-chart {
    font-size:13px;
    font-family: 'museo_500';
    color: #ffffff;
    margin:20px 0;
    width:100%;
    max-width:290px;
    background-color: var(--color-green);
}
#main .fuel-chart {
    margin:0 5px;
    width: auto;
    max-width: none;
}
.fuel-chart__body {
    padding:20px 20px 0 20px;
}
.fuel-chart__headline {
    font-size:18px;
    font-family: museo_700;
}

.fuel-chart__current {
    padding:10px 0 15px 0;
    border:1px solid rgba(255,255,255,.5);
    border-width:1px 0;
    margin:5px 0 15px 0;
    font-family: museo_700;
}
.fuel-chart__img {
    display: block;
    width:40px;
    height:40px;
    opacity: .8;
}
.fuel-chart__percent {
    font-size:15px;
    font-family: museo_700;
    opacity: .8;
}
.fuel-chart__current-price {
    font-size:30px;
}
.fuel-chart__yesterday {
    margin: 20px 0;
    font-family: museo_700;
}
.fuel-chart__yesterday-price {
    font-size:18px;
}
.fuel-chart__info {
    opacity: .65;
    margin: 20px 0;
}

.fuel-chart__bottom {
    display: block;
    font-size:22px;
    padding:15px 20px;
    color:#000;
    background:#e2e2e8;
    text-align: center;
}
a.fuel-chart__bottom:hover,
a.fuel-chart__bottom:focus {
    background: #d4d4dd;
}