/*Margins & Paddings*/
.mt-0 { margin-top: 0; }
.mt-5 { margin-top: 5px; }
.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-10-important { margin-top: 10px !important; }

.mt-20 { margin-top: 10px; }
.mt-30 { margin-top: 15px; }
.mt-40 { margin-top: 20px; }

.mb-0 { margin-bottom: 0; }
.mb-5 { margin-bottom: 5px; }
.mb-10 { margin-bottom: 10px; }
.mb-20 { margin-bottom: 10px; }
.mb-30 { margin-bottom: 15px; }
.mb-40 { margin-bottom: 15px; }

.mr5 { margin-right: 5px; }
.mr10 { margin-right: 10px; }
.mr15 { margin-right: 10px; }
.mr20 { margin-right: 20px; }
.mr30 { margin-right: 30px; }

.p30 { padding: 30px; }
.pt0 { padding-top: 0px; }
.pb0 { padding-bottom: 0px; }

.ml0 { margin-left: 0; }
.ml5 { margin-left: 5px; }
.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }
.ml30 { margin-left: 30px; }
.m-10-0 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-auto { margin: auto; }

@media screen and (min-width: 768px) {
    .mb30-on-sm {
        margin-bottom: 30px;
    }
    .mt-20 { margin-top: 15px; }
    .mt-30 { margin-top: 20px; }
    .mt-40 { margin-top: 30px; }
    .mr15 { margin-right: 15px; }
    .mr20 {margin-right: 20px; }
    .mb-20 { margin-bottom: 15px; }
    .mb-30 { margin-bottom: 20px; }
    .mb-40 { margin-bottom: 30px; }
}

@media screen and (min-width: 768px) {

    .mt-20 { margin-top: 20px; }
    .mt-30 { margin-top: 30px; }
    .mt-40 { margin-top: 40px; }
    .mb-20 { margin-bottom: 20px; }
}


.no-padding {
    padding: 0;
}

.no-padding-important {
    padding: 0 !important;
}

.no-margin.no-margin {
    margin: 0;
}

.pb0 { padding-bottom: 0px !important; }
.p10 { padding: 10px; }
.p20 { padding: 20px; }
.p20-important { padding: 20px !important; }

.pl40 { padding-left: 40px; }
.pr40 { padding-right: 40px; }
.pr20 { padding-right: 20px;}

.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-width-0 > .col {
    padding-left: 0;
    padding-right: 0;
}
.row.row--gutter-width-1 {
    margin-left: -1px;
    margin-right: -1px;
}
.row.row--gutter-width-1 > .col {
    padding-left: 1px;
    padding-right: 1px;
}
.row.row--gutter-width-2 {
    margin-left: -2px;
    margin-right: -2px;
}
.row.row--gutter-width-2 > .col {
    padding-left: 2px;
    padding-right: 2px;
}
.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-width-10 > .col {
    padding-left: 5px;
    padding-right: 5px;
}
.row--gutter-width-18 {
    margin-left: -9px;
    margin-right: -9px;
}
.row--gutter-width-18 > .col {
    padding-left: 9px;
    padding-right: 9px;
}
.row--gutter-width-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row--gutter-width-20 > .col {
    padding-left: 10px;
    padding-right: 10px;
}
.row--gutter-width-24 {
    margin-left: -12px;
    margin-right: -12px;
}
.row--gutter-width-24 > .col {
    padding-left: 12px;
    padding-right: 12px;
}
.row--gutter-width-30 {
    margin-left: -15px;
    margin-right: -15px;
}
.row--gutter-width-30 > .col {
    padding-left: 15px;
    padding-right: 15px;
}
.row--gutter-width-36 {
    margin-left: -18px;
    margin-right: -18px;
}
.row--gutter-width-36>.col, .row--gutter-width-36.content-slider .col {
    padding-left: 18px;
    padding-right: 18px;
}
.row--gutter-width-60 {
    margin-left: -30px;
    margin-right: -30px;
}
.row--gutter-width-60 > .col {
    padding-left: 30px;
    padding-right: 30px;
}

@media screen and (min-width: 768px) {
    .row.row--gutter-width-15 {
        margin-left: -15px;
        margin-right: -15px;
    }
    .row--gutter-width-15 > .col {
        padding-left: 15px;
        padding-right: 15px;
    }
    .row--gutter-width-40 {
        margin-left: -20px;
        margin-right: -20px;
    }
    .row--gutter-width-40 > .col {
        padding-left: 20px;
        padding-right: 20px;
    }
    .row--center-cols {
        font-size: 0;
        text-align: center;
    }
    .row--center-cols > .col {
        display: inline-block;
        float: none;
        font-size: 16px;
        vertical-align: top;
        text-align: left;
    }
}

/*vertical gutter*/
.row.row--vertical-gutter-36 {
    margin-top: -15px;
}
.row.row--vertical-gutter-36 > .col {
    margin-top: 15px;
}
@media screen and (min-width: 768px) {
    .row.row--vertical-gutter-20 {
        margin-top: -20px;
    }
    .row.row--vertical-gutter-20 > .col {
        margin-top: 20px;
    }

    .row.row--vertical-gutter-36 {
        margin-top: -36px;
    }
    .row.row--vertical-gutter-36 > .col {
        margin-top: 36px;
    }
    .pr20-sm{
        padding-right: 20px;
    }

    /* row--same-height */

    .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
        height: auto;
    }
    .row--same-height {
        display: flex;

        flex-direction: row;
        flex-wrap: wrap;
    }
    .row--same-height:before,
    .row--same-height:after {
        display: none;
    }
    .row--same-height > .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .row--same-height__item--justify-end {
        justify-content: flex-end;
    }
    .row--same-height__item--justify-center {
        justify-content: center;
    }

}
.row--same-height__item--align-center {
    align-items: center;
}

@media screen and (max-width: 767px) {
    .row--same-height {
        flex-wrap: wrap;
    }
    .row.row--vertical-gutter-20 {
        margin-top: -10px;
    }
    .row.row--vertical-gutter-20 > .col {
        margin-top: 10px;
    }
    .p30{
        padding: 20px;
    }
    .no-margin-xs{
        margin: 0 !important;
    }
    .mt-xs-0 {
        margin-top: 0;
    }
}



/*on xs and up*/
.row--same-height-on-xs .row--same-height__item {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.row--same-height-on-xs {
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
}
.row--same-height-on-xs > .col {
    display: flex;
    flex-direction: column;
    height: auto;
}
.row--same-height-on-xs:before,
.row--same-height-on-xs:after {
    display: none;
}

.row--same-height-on-xs .row--same-height__item--justify-end {
    justify-content: flex-end;
}
.row--same-height-on-xs .row--same-height__item--justify-center {
    justify-content: center;
}

.row--same-height .row--same-height__item__bottom-item {
    margin-top: auto;
}

.row--same-height .row--same-height__item__grow {
    flex-grow: 1;
}


@media screen and (max-width: 767px) {
    .row--has-xs-colmargin > .col + .col {
        margin-top: 15px;
    }
    .xs-text-right {
        text-align: right;
    }
}


/* FLEX-WRAPPER-STUFF
 ---------------------------*/
.flex-wrapper {
    display: flex;
    align-items: center;
}
.flex-wrapper.flex-wrapper--space-between {
    justify-content: space-between;
}
.flex-wrapper.flex-wrapper--align-end {
    align-items: flex-end;
}
.flex-wrapper.flex-justify-center {
    justify-content: center;
}
nav.list-with-icons > ul > li {
    display: flex;
    align-items: center;
    padding: 2px 0;
}





.inline-block {
    display: inline-block;
}

/*js-styling*/
.isClickable,
[data-toggle="collapse"] {
    cursor: pointer;
}

/* MISC */
.block {
    display: block;
}
.strong {
    font-weight: 700;
}
.warning {
    color: #fff028;
}
.caret.caret--big {
    border-top: 7px dashed;
    border-top: 7px solid \9;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}
.gotoLink {
    cursor: pointer;
}
.clear {
    clear: both;
}
.js-dropdown {
    position: relative;
}
.relative {
    position: relative;
}
@media screen and (max-width: 767px) {
    .xs-inline-block {
        display: inline-block;
    }
    .xs-mt-15 {
        margin-top: 15px;
    }
    .xs-mt-10 {
        margin-top: 10px;
    }
}

@media screen and (min-width: 768px) {

    .xs-on-pull-right {
        float: right;
    }
}


.color-green {
     color: var(--color-green);
}
.color-red {
    color: var(--color-red);
}
.color-yellow {
    color: var(--color-bg-third);
}
.color-lightgrey {
    color: var(--color-light-grey);
}
.color-darkgrey {
    color: var(--color-dark-grey);
}
.color-brown {
    color: var(--color-brown);
}

.color-orange {
    color: #e49439;
}

.oblique {
    font-style: italic;
}

.font-default {
    font-family: var(--font-default);
}


@media screen and (max-width: 767px) {

    .xs-no-padding { padding: 0; }
    .xs-no-padding-important {
        padding: 0 !important;
    }

    label {
        margin-bottom: 2px;
    }
    .xs-text-center {
        text-align: center;
    }
}

@media screen and (min-width: 768px){
    .text-right-sm{
        text-align: right;
    }
}

@media screen and (max-width: 767px){
    .text-right-xs{
        text-align: right;
    }
}


/* DROPDOWNS */
.dropdown-menu .icon--menu-collapse {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 8px 5px;
    font-size: 12px;
    display: none;
}
.dropdown-menu .has-dropdown .icon--menu-collapse {
    display: block;
}
/* 2nd level */
.dropdown-menu li > ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
}
.dropdown-menu li.is-open > ul {
    display: block;
}

iframe[name="google_conversion_frame"]{
    display: none;
}


.d-ib{
    display: inline-block;
}

@media screen and (max-width: 767px){
    .text-center-xs{
        text-align: center;
    }
}

/* LISTS */
.list-inline.list--has-divider {
    margin-left: -10px;
}
.list--has-divider li { padding: 0; }
.list--has-divider li a {
    padding-left: 10px;
    padding-right: 10px;
}
.list--has-divider li:not(:first-of-type) > a {
    border-left: 1px solid var(--color-light-grey);
}


/* MULTI-COLUMN */
.multi-column + .multi-column { margin-top: 15px; }
@media screen and (min-width: 768px) {
    .multi-column + .multi-column { margin-top: 20px; }
    .multi-column {
        column-count: 2;
        column-gap: 20px;
    }
    .multi-column ul li,
    .multi-column ol li {
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }
}


/* jumbo */
.jumbotron {
    padding: 15px;
    background-color: var(--color-bg-primary);
}


@media screen and (min-width: 768px) {
    .jumbotron {
        padding: 30px;
    }
}
@media screen and (min-width: 1200px) {
    .jumbotron {
        padding: 50px;
    }
}



/* RADIO-BUTTONS */
.form-group .radio input[type="radio"] {
    visibility: hidden;
    margin: 0;
}
.form-group .radio label {
    padding-left: 30px;
}
.form-group .radio label:before {
    content: '';
    position: absolute;
    left:0;
    top: 0px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #e2e2e8;
}
.form-group .radio input[type="radio"] + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #1b6d3c;
    opacity: 0;
}
.form-group .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    transition: opacity 0.15s;
}

.form-group .radio:first-of-type {
    margin-top: 5px;
}
@media screen and (min-width: 768px) {
    .form-group .radio label:before {
        top: 1px;
    }
    .form-group .radio input[type="radio"] + label:after {
        top: 5px;
    }
}


/* CHECKBOXES :: ::
  ---------------------  */
.checkbox-wrap {
    position: relative;
}
.checkbox-wrap input[type="checkbox"] {
    visibility: hidden;
    margin: 0;
}
.checkbox-wrap label {
    padding-left: 35px;
    user-select: none;
}

.checkbox-wrap label:before {
    content: '';
    position: absolute;
    left:0;
    top: 0px;
    width: 19px;
    height: 19px;
    background: #fff;
    border: 1px solid #e2e2e8;
    box-shadow: 0 2px 0 rgba(0,0,0,.15) inset;
}
.checkbox-wrap input[type="checkbox"] + label:after {
    content: "\E009";
    font-family: "iconfont";
    position: absolute;
    left: 3px;
    top: -4px;
    font-size: 17px;
    color: #118516;
    opacity: 0;
}
.checkbox-wrap input[type="checkbox"]:checked + label:after {
    opacity: 1;
    transition: opacity 0.15s;
}

.checkbox-wrap input[type="checkbox"]:disabled + label {
    opacity: .5;
    cursor: default;
}


.form-group--phone {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.form-group--phone__col {
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 5px;
    padding-left: 5px;
}
.form-group--phone__col-sm {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
}

/*Center cols*/
.row--inline-block {
    font-size: 0;
}

.row--inline-block> .col.text-right{
    text-align: right;
}

.row--inline-block>.col {
    display: inline-block;
    float: none;
    font-size: 17px;
    vertical-align: top;
    text-align: left;
}
.row--inline-block>.col.text-right-sm {
    text-align: right;
}


.row--inline-block--middle>.col {
    vertical-align: middle;
}

.row--inline-block--bottom>.col {
    vertical-align: bottom;
}

.row--inline-block--middle.text-center>.col,
.row--inline-block--middle>.col.text-center{
    text-align: center;
}









/* datepicker-icon */
.datepicker-wrapper {
    position: relative;
    margin: 0;
    padding: 0;
}
.has-datepicker + .datepicker-wrapper__icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-green);
    pointer-events: none;
}
.datepicker-wrapper__icon.datepicker-wrapper__icon--grey {
    color: #585d5f;
}

/* form-stuff */
select.form-control option:disabled {
    color:#ccc;
}

.form-infotext {
    font-size: 11px;
}
@media screen and (max-width: 767px) {
    select.form-control {
        /* -webkit-appearance: none;*/
    }
    .form-control {
        border-radius: 0;
    }

    .form-infotext {
        font-size: 9px;
    }
}


/* TEXT -- BG - HELPER
 --------------------- */
/*Text Helper*/
.text-truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.text-color-inherited {
    color: inherit;
}
.text-underline {
    text-decoration: underline;
}
.text-defaultcase { text-transform: none; }
.text-secondary {
    color: var(--text-secondary);
}

.text-muted {
    color: #777777 !important;
}
.text-primary {
    color: var( --color-primary);
}
a.text-primary:hover,
a.text-primary:focus {
    color: #000;
}
.text-success {
    color: #3c763d;
}
.text-green{
    color: var(--color-green);
}
a.text-success:hover,
a.text-success:focus {
    color: #2b542c;
}
.text-info {
    color: #31708f;
}
a.text-info:hover,
a.text-info:focus {
    color: #245269;
}
.text-warning {
    color: #8a6d3b;
}
a.text-warning:hover,
a.text-warning:focus {
    color: #66512c;
}
.text-danger {
    color: #a94442;
}
a.text-danger:hover,
a.text-danger:focus {
    color: #843534;
}
.text-grey-light {
    color: #9C9FA3;
}
.text-chart-high {
    color: #dd2b2b;
}
.text-chart-mid {
    color:yellow;
}
.text-chart-low {
    color: #fff;
}
.bg-primary {
    color: var(--color-primary);
    background-color: var(--color-bg-primary);
}
.bg-secondary {
    color: var(--color-secondary);
    background-color: var(--color-bg-secondary);
}
.bg-third {
    color: var(--color-primary);
    background-color: var(--color-bg-third);
}
/*a.bg-primary:hover,
a.bg-primary:focus {
    background-color: #286090;
}*/
.bg-success {
    background-color: #dff0d8;
}
a.bg-success:hover,
a.bg-success:focus {
    background-color: #c1e2b3;
}
.bg-info {
    background-color: #ebebef;
    color: var(--color-primary);
}

/*a.bg-info:hover,
a.bg-info:focus {
    background-color: #afd9ee;
}*/
.bg-warning {
    background-color: #fcf8e3;
}
a.bg-warning:hover,
a.bg-warning:focus {
    background-color: #f7ecb5;
}
.bg-danger {
    background-color: #f2dede;
}
a.bg-danger:hover,
a.bg-danger:focus {
    background-color: #e4b9b9;
}

.tooltip-w-icon {
    position: relative;
    top:3px;
    margin-left:5px;
    display: inline-block;
    width:16px;
    height:16px;
    border-radius: 50%;
    background-color: var(--color-bg-secondary);
    color:#fff;
}
.tooltip-w-icon>.icon {
    font-size: 10px;
    position: absolute;
    left:50%;
    top:50%;
    transform: translateY(-50%) translateX(-50%);
}

.link-underlined{
    text-decoration: underline;
    color: var(--color-green);
}

.bauproduktenverordnung-form .jumbotron{
    position: relative;
}


@media screen and (min-width: 768px){
    .bauproduktenverordnung-info{
        position: absolute;
        bottom: 20px;
        right: 20px;

    }
}

@media screen and (max-width: 767px){
    .bauproduktenverordnung-info{
        margin-top: 15px;
        margin-left: 5px;
    }

    .bauproduktenverordnung-form .btn{
        margin-top: 15px;
    }

}

.embed-responsive-10by3{
    padding-top: calc(3 / 10 * 100%);
}

.embed-responsive-6by7{
    padding-top: calc(7 / 6 * 100%);
}

.embed-responsive-vehicles{
    padding-top: 100%;
}

@media screen and (min-width: 768px){
    .embed-responsive-vehicles{
        padding-top: 75%;
    }

}


.flex-grow-1 {
    flex-grow: 1;
}
.flex-grow-0 {
    flex-grow: 0;
}
.icon-in-text {
    font-size: calc(19em / 16);
    vertical-align: -.2em;
    margin-right: .15em;
}
.icon-in-text.icon-mail {
    font-size: calc(15em / 16);
    vertical-align: -.15em;
}

.collapsed > .collapse-link__icon {
    transform: rotate(45deg);
}
.collapse-link__icon {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background: var(--color-green);
    color: #fff;
    /*position: absolute;*/
    /*right: 0;*/
    /*top: 0;*/
    padding: 6px;
    font-size: 6px;
    transition: transform 250ms ease-in-out;
}
@media screen and (min-width: 768px) {
    .collapse-link__icon {
        width: 25px;
        height: 25px;
        padding: 9px 8px;
        font-size: 8px;
        top: -2px;
    }
}

.modal-header__close {
    position: absolute;
    right: 15px;
    top: 15px;
}