/* LOCATION SLIDE */

/* slide has-form */
.has-location-form hgroup > .h-item {
    color: var(--color-green);
    margin: 0;
    padding: 0;
    font-family: 'museo_700';
}
.form-location-search > .form-group {
    margin: 0;
    flex-grow: 1;
}
.location-search-small {
    margin-bottom: 30px;
}
.location-search-small label {
    font-size: 18px;
    color: #585d5f;
    font-weight:400;
    font-family:var(--font-headline-default);
    margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
    .location-search-small {
        margin-bottom: 80px;
    }
    .location-search-small label {
        margin-right: 20px;
        margin-bottom: 0;
    }
    .location-search-small .form-control {
        max-width: 78px;
        text-align: left;
    }
}
.location-search-small .form-control {
    padding: 9px 14px;
    text-align: center;
}

.payment-icon {
    margin-right: 5px;
}

.service-img-big {
    max-height: 74px;
    max-width: 74px;
}

.form-location-search button ~ a.btn {
    border-left: 1px solid #fff;
}

@media screen and (max-width: 767px) {
    .content-section--location-slide {
        padding-bottom: 10px;
    }

    .has-location-form {
        background: url(/static/img/xs-sbg-map.png) center -60px no-repeat;
        background-size: contain;
        padding-bottom: 40px;
    }
    .has-location-form hgroup > .h-item:first-of-type {
        font-size: 14px;
    }
    .has-location-form hgroup > .h-item:last-of-type {
        font-size: 28px;
    }
    
    .has-location-form hgroup  {
        /* padding-left: 35px; */
        /*background: url(/static/img/xs-sbg-wappen.png) left top no-repeat;*/
        margin-bottom:15px;
    }

    .has-location-form .form-location-search {
        padding: 0;
        margin: 0;
        display: block;
    }
    .has-location-form .form-location-search > .form-group {
        width: 100%;
        float: left;
    }
    .has-location-form .form-location-search > button {
        width: 100%;
        margin-top: 15px;
    }
    .has-location-form .form-location-search > a {
        width: 100%;
        margin-top: 15px;
    }


}

@media screen and (min-width: 768px) {
    .content-section--location-slide {
        padding: 0 20px 20px 20px;
    }
    .has-location-form {
        padding-top: 20px;
    }

    .content-section--location-slide {
        background-image: linear-gradient(180deg, white 0%, #f5f5fa 100%);
    }

    .has-location-form hgroup > .h-item {
        display: inline-block;
    }

    #search-locations {
        height: 60px;
    }

    .has-location-form h3 {
        margin-top: 20px;
    }

    .form-location-search button,
    .form-location-search a.btn {
        height: 60px;
        line-height: 60px;
        white-space: nowrap;
        padding-top: 0;
    }

}

@media screen and (min-width: 1200px) {
    .content-section--location-slide {
        padding: 0 25px 25px 25px;
    }
    .has-location-form {
        padding-top: 50px;
    }

    #search-locations {
        height: 80px;
        font-size: 22px;
        padding: 8px 20px;
    }

    .has-location-form hgroup > .h-item{
        font-size: 42px;
    }
    .has-location-form h3 {
        font-size: 26px;
    }
    .form-location-search button,
    .form-location-search a.btn {
        height: 80px;
        line-height: 80px;
    }
    .location-finder-btn{
        color: white;
        font-size: 33px;
        line-height: 50px;
        width: 280px;
    }
    .content-section--location-slide h2.h-item.location-finder-heading{
        font-size: 60px;
        line-height: 72px;
    }
}


/* slide has-map */
@media screen and (min-width: 768px) {
    .has-sbg-loc-map {
        background: url(/static/img/location-map-sbg.png) center center no-repeat;
        background-size: contain;
    }
}


#search-locations.has-error:not(:focus) {
    color: #b94a48;
    background-color: #f2dede;
    border: 1px solid #eed3d7;
}

@media screen and (min-width: 768px){
    /* no-stores-found */
    .location-finder-results .no-stores {
        margin-top: 20px;
    }
}

.location-finder-results .no-stores>.icon{
    margin-right: 10px;
}



/* LOCATION RESULT
 -------------------------------- */
.location-finder-results .row--same-height__item {
    flex-grow: 0;
}
.location-finder-results .item__inner {
    padding: 15px;
    position: relative;
    background: var(--color-bg-primary);
}
@media screen and (min-width: 768px) {
    .location-finder-results .item__inner {
        padding: 20px 24px;
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        flex-grow:1;
    }
}

@media screen and (max-width: 767px) {
    .location-finder-results.row--same-height > .col {
        display: block;
        width:100%;
    }
    .location-finder-btn{
        line-height: 35px !important;
    }
}


.item__inner .item__head--name {
    color: var(--color-green);
    font-family:var(--font-headline-default);
    font-weight: 400;
    font-size: 19px;
    line-height:calc(24/20);
    margin-bottom: 14px;
}
.item__body__address {
    margin-top: 14px;
    font-size: 14px;
    line-height:calc(22/14);
}

.item__body__address__link {
    color: var(--color-green);
}
.item__body__address__link:hover {
    text-decoration: underline;
}
.item__body__address__link>.icon {
    width: 20px;
    text-align: center;
    margin-right: 10px;
    font-size: 16px;
    vertical-align: -.2em;
}
.item__body__address__link>.icon-mail {
    font-size: 15px;
}
.item__inner .item__body .btn-block {
    margin: 5px 0;
    padding: 3px 0;
    border-bottom: 1px solid var(--color-light-grey);
}
.active-location .item__button .btn {
    background: #e7e7e7;
}

/* OPENING TIMES */
table.opening-times {
    width:100%;
    margin-top: 5px;
    margin-bottom: 5px;
}
.wysiwyg table.opening-times tr,
.wysiwyg table.opening-times td {
     padding: 5px;
     font-size: 90%;
     vertical-align: top;
}
.location-finder-results .wysiwyg table.opening-times td {
    background: rgba(17,133,22,.1);
}

table.opening-times.opening-times-foot {
    padding-top: 0;
    margin-top: 0;
}

.opening-times-wrapper .open--opening-times .color-red {
    color: var(--color-red);
}

.opening-times-wrapper .open--opening-times .icon-plus {
    color: var(--color-light-grey);
}

.opening-times-label {
    font-size: 100%;
}


@media screen and (max-width: 767px) {
    .location-finder-results > .col + .col {
        margin-top:15px;
    }

    .opening-times-label {
        font-size: 100%;
    }
    .item__button {
        margin-top: 5px;
    }
}

/* LOCATION DETAIL
 --------------------*/
.has-store-detail-infos .detail-headline {
    background: #118516;
    color: #fff;
    padding: 3px 8px;
    display: inline-block;
    width: 100%;
}
.has-store-detail-infos .btn-block + .btn-block {
    margin-top: 3px;
}

#search-locations::-webkit-input-placeholder{
    color: #555;;
}








