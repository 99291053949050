
@media screen and (max-width: 767px) {
    .content-formular, .formular-send-wrapper {
        padding: 10px 5px;
    }
}
@media screen and (min-width: 768px) {
    .content-formular, .formular-send-wrapper {
        padding: 15px 20px;
    }
}

.content-formular {
    background-color: var(--color-bg-primary);
    padding-bottom: 20px;
}


.content-formular.content-formular--newsletter{
    padding: 30px;
}

@media screen and (min-width: 992px){
    .content-formular.content-formular--newsletter{
        padding: 40px 120px;
    }
}

@media screen and (min-width: 768px){
    .content-formular.content-formular--newsletter{
        padding: 40px 80px;
    }
}


.info-section {
    background-color: var(--color-bg-primary);
    padding: 15px 20px;
}



/* custormer forms
 ----------------- */

.account-sidebar .sidebar-group {
    padding-top: 15px;
    padding-bottom: 15px;
}

.account-sidebar > .sidebar-group + .sidebar-group {
    border-top: 2px solid #fff;
}

@media screen and (min-width: 768px) {
    .form--user .form-group {
        margin-bottom: 10px;
    }
    .account-sidebar .sidebar-group {
        padding-left: 5px;
        padding-right: 5px;
    }
}

/* pagination in orderlist */

.pagination>li:first-child>a, .pagination>li:first-child>span {
    border-radius: 0;
}

.pagination>li>a, .pagination>li>span {
    color: #2b3133;
}

.pagination>.active>a, .pagination>.active>span, .pagination>.active>a:hover, .pagination>.active>span:hover, .pagination>.active>a:focus, .pagination>.active>span:focus {
    background-color: #ddd;
    border-color: #ddd;
    color: #2b3133;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
    border-radius: 0;
}

.pagination>li>a:hover, .pagination>li>span:hover, .pagination>li>a:focus, .pagination>li>span:focus {
    color: #2b3133;
}

/*sidebar*/
.account-sidebar {
    border: 1px solid #d9d9e1;
    background: #fff;
    margin-bottom: 20px;
}
.account-sidebar li + li .account-sidebar__item {
    border-top:1px solid #d9d9e1;
}
.account-sidebar__item {
    position: relative;
    padding: 18px 54px;
    display: block;
    font-family:var(--font-headline-bold);
    font-size: 20px;
    line-height:calc(24/20);
}
@media screen and (min-width: 768px) {
    .account-sidebar__item {
        padding: 28px 54px;
    }

    .account-sidebar__item:after {
        content: "\E003";
        font-family: "iconfont";
        speak: none;
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%) translateX(-100%);
        font-size: 12px;
        opacity: 0;
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    }
    .active>.account-sidebar__item:after,
    .account-sidebar__item:hover:after {
        transform: translateY(-50%);
        opacity: 1;
    }
}
.active .account-sidebar__item {
    background-color: var(--color-green);
    color: #fff;
}
.account-sidebar__item__icon {
    position: absolute;
    width: 20px;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);
}
.account-sidebar__item__icon.icon-shopping_cart {
    font-size: 17px;
}