.breadcrumb {
    padding: 8px 15px;
    font-size: 13px;
    list-style: none;
    color: #2b3133;
    border:0;
    margin-bottom: 10px;
    position: relative;
}
.breadcrumb-container {
    position: relative;
}
.breadcrumb-container:after {
    content:'';
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    width: auto;
    height:1px;
    background:#e2e2e8;
}
.breadcrumb > li {
    display: inline-block;
}
.breadcrumb > li > a:hover {
    text-decoration: underline;
}
.breadcrumb > li + li:before {
    content: "/\00a0";
    padding: 0 1px;
}


@media screen and (max-width: 767px){
    .breadcrumb {
        white-space: nowrap;
    }
    .breadcrumb > li:last-child {
        padding-right:20px;
    }
    .breadcrumb-scroll {
        padding-top:6px;
        overflow-x: auto;
    }
}

@media screen and (min-width: 992px) {
    .breadcrumb {
        padding: 15px;
    }
}