.live-search {
    position: absolute;
    z-index: 2000;
    background-color: #fff;
    border: 1px solid #E2E2E8;
    border-top: 0;
    width: 100%;
}
.live-search.loading {
    -webkit-filter: blur(2px);
    filter: blur(2px);
}

.navbar-form.main-search > .form-group {
    position: relative;
}

.search-group > li {
    padding: 5px 22px 5px 30px;
    position: relative;
    white-space: normal;
}

.search-group > li:hover {
    background-color: #f9f9f9;
}

.search-group > li.active {
    background-color: #f9f9f9;
}
.live-search__title {
    font-weight: 700;
}
.live-search__icon {
    color: #D0D0DA;
    font-size: 16px;
    position: absolute;
    left: 2px;
    top: 7px;
}

.search-group-wrapper + .search-group-wrapper {

    border-top: 1px solid #E2E2E8;
}


.search-group-headline {
    display: block;
    font-family: museo_700;
    margin: 30px 20px 0;
    color: var(--color-dark-grey);
    font-size: 17px;
}

.search-group {
    padding: 20px 0 10px;
    margin: 0 10px;
}
@media screen and (min-width: 768px) {
    .search-group {
        padding: 10px 0;
        margin: 0 20px;
    }
}

.search-highlight {
    font-weight: bold;
}

.search-highlight-color {
    font-weight: bold;
    color: var(--color-green);
}

/** mobile **/
.mobile-search-button {
    height: 40px;
    line-height: 1;
    background: #fff;
    color:#000;
}
.live-search-wrapper-mobile {
    background: #fff;
}

@media screen and (max-width: 767px) {
    .live-search-wrapper-mobile {
        width: 100vw;
        position: absolute;
        left: auto;
        right: -55px;
        top: 50px;
    }
}
@media screen and (min-width:375px) and (max-width: 767px) {
    .live-search-wrapper-mobile {
        top: 58px;
    }
}




/* tabbed search (suche-neu) */
.search-result-wrapper .content-section {
    margin-top: 0;
}