.newsletter-slide {
    padding: 25px 5px;
    margin-bottom: 5px;
}
@media screen and (min-width: 768px) {
    .newsletter-slide {
        padding: 25px 0;
        margin-bottom: 0;
    }
}
.newsletter-slide__title {
    font-size: 19px;
    line-height: calc(24/20);
    font-family: var(--font-headline-default);
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .newsletter-slide__title {
        text-align: right;
        margin-top: 9px;
        margin-bottom: 0;
    }
}

.newsletter-slide a {
    color: white;
}

.newsletter-slide a:hover {
    color: #d0d0da;
}