/* IMAGE AND TEXT
---------------------- */
/*.has-text-and-image {*/
    /*display: flex;*/
/*}*/
/*.has-text-and-image.text-first .col-has-image {*/
    /*order: 2;*/
/*}*/
/*.has-text-and-image.text-first .col-has-text {*/
    /*order: 1;*/
/*}*/


/* IMAGE AND VIDEO
---------------------- */

/* reset after pim */
.has-img-and-video__video > .col {
   padding: 0;
}


@media screen and (max-width: 767px) {
    .has-img-and-video .has-img-and-video__video {
        margin-bottom: 2px;
    }
    .has-img-and-video__img > .row > .col + .col {
        margin-top: 2px;
    }

    .has-img-and-video__video > .col {
       /* width: 100%;*/
        margin-bottom: 2px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .container--main:not(.container--has-sidebar) .has-img-and-video .has-img-and-video__video {
        margin-bottom: 4px;
    }

    .has-img-and-video__video > .col {
        width: 100%;
        margin-bottom: 4px;
    }

}


@media screen and (min-width: 1200px) {
    .has-img-and-video .has-img-and-video__video { float: right; }
    .has-img-and-video .has-img-and-video__img { float: left; }

    .container--main:not(.container--has-sidebar) .has-img-and-video__img > .row > .col + .col {
        padding-top: 2px;
    }
}


/* CONTAINER MAIN HAS SIDEBAR
----------------------------- */
@media screen and (min-width: 768px) {

    .container--has-sidebar .has-img-and-video .has-img-and-video__video {
        width: 100%;
    }
    .container--has-sidebar .has-img-and-video .has-img-and-video__img {
        width: 100%;
    }
    .container--has-sidebar .has-img-and-video .has-img-and-video__img .col {
        width: 50%;
        padding-top: 4px;
    }


}
