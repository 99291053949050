/* normal background-color for abverkauf-item*/
.product-item.product-item--sale .product-item__body:before{
    content: '';
    z-index: -1;
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f2f2f7;
}
/* hover background*/
.product-item.product-item--sale .product-item__body:after {
    background-image: linear-gradient(0deg, #f9f9f9 0%, #f5f5fa 100%);
    background-color: #f9f9f9;
}

.product-item.product-item--sale:before,
.product-item.product-item--sale:nth-child(4n+4):after,
.product-item.product-item--sale:last-of-type:after{
   display: none;
}

.product-item.product-item--sale:hover {
    border: 1px solid transparent;
}

/* product-grid-item */

.product-item .teaser__promotion {
    position: relative;
    top: 0;
    display: inline-block;
}

/* RATING */
.product-item__rating {
    padding: 0px 5px;
    border: 1px solid #f8d01c;
    color: #f8d01c;
    display: block;
}

/* PROD_DESCRIPTION */
.product-item__description > p {
    margin: 0;
}

/* PRODUCT - PRICE-STUFF */
.product-item__body .price-wrapper__price-old {
    display: block;
    padding: 5px 0;
}

.product-item__body .price-wrapper__price-per-unit {
    display: block;
    min-height: 1em;
    box-sizing: content-box;
}

/* PROD_DESCRIPTION */
.product-item .price-wrapper {
    position: relative;
    display: block;
}

.product-item__name {
    min-height: 30px;
}

/* WISHLIST Button*/
.product-item__body .has-btn-heart .btn-add-to-wishlist {
    color: var(--color-light-grey);
}

.product-item--sale .product-item__body .has-btn-heart .btn-add-to-wishlist{
    color: var(--color-dark-grey);
}

.product-item__body .has-discount-percent {
    text-align: right;
    line-height:1.1;
    font-style: italic;
    color: var(--text-secondary);
}

.product-item.product-item--sale .product-item__body .has-discount-percent {
    color: var(--color-discount-secondary);
}

@media screen and (max-width: 767px) {

    .product-item__body .has-discount-percent {
        text-align: left;
    }
    
    /* img-promo-ratin */
    .product-item .teaser__promotion {
        position: absolute;
        top: 0;
        padding-left: 10px;
        left: -10px;
    }

    .product-item__rating {
        position: relative;
        text-align: center;
        margin-top: 10px;
    }

    /* headline - text - price */
    .product-item__name {
        min-height: 35px;
    }

    .product-item__description {
        font-size: 11px;
    }

    .product-item__body .price-wrapper__price-current {
        display: inline-block;
    }

    .product-item__body .has-discount-percent {
        margin-top: 5px;
    }

    .product-item__body .has-buttons {
        margin-top: 10px;
    }
    .product-item__body .has-btn-heart {
        text-align: left;
    }
    .product-item__body .has-btn-heart .btn-add-to-wishlist {
        display: inline-block;
        padding: 8px 10px 8px 15px;
        line-height: 0;
    }
    .product-item__body .has-btn-heart .btn-add-to-wishlist > .icon {
        font-size: 22px;
    }

    .btn.xs-icon-only .icon {
        font-size: 20px;
    }

}


@media screen and (min-width: 768px) {

    /* PRODUCT-ITEM GRID STUsFF */
    .product-item {
        padding: 0;
        border: 1px solid transparent;
        margin-bottom: 15px;
    }

    .product-item__body {
        padding: 15px;
        display: flex;
        flex-grow: 1;
    }
    .product-item__body > .row,
    .product-item--next-page .page-next-wrapper,
    .product-item__teaser .row {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
       /* make this flexbox markup work in ie11*/
        min-width: 1px;
        ms-flex: 1 0 0px;
    }
    .product-item--next-page .page-next-wrapper {
        justify-content: center;
        text-align: center;
    }
    .product-item__body .has-prod-info {
        display: flex;
        flex-direction:column;
        flex-grow: 1;
        justify-content: space-between;
    }

    .product-item__body .has-img-rating > .em-holder {
        min-height: 245px;
        position: relative;
    }
    .product-item .teaser__promotion {
        position: absolute;
        bottom: 20px;
        top: auto;
    }
    .product-item .teaser__promotion + .teaser__promotion {
        bottom: 43px;
    }
    .product-item .product-item__rating {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .product-item:hover .product-item__body {
        border: none;
    }

    .product-item__name {
        margin-top: 10px;
    }

    .product-item__description {
        font-size: 12px;
    }

    .product-item__body .price-wrapper__price-current {
        padding: 8px 10px;
    }

    .product-item .teaser__promotion {
        margin: 5px 0;
    }

    /* CONTENTS */
    .product-item__body .has-btn-heart {
        text-align: center;
    }

    .product-item + .product-item:before,
    .product-item:first-of-type:before {
        content: '';
        position: absolute;
        top: 0;
        left: -1px;
        width: 1px;
        bottom: 20px;
        background: #e2e2e8;
    }

    .product-item.product-item--sale + .product-item:before {
        display: none;
    }

}


@media screen and (min-width: 992px) {

    .product-item {
        margin-bottom: 25px;
    }

    .product-item:hover {
        border: 1px solid #e2e2e8;
    }

    /* bg-hover */
    .product-item__body {
        overflow: hidden;
        position: relative;
    }
    .product-item__body:after {
        content: '';
        background-image: linear-gradient(0deg, white 0%, #f5f5fa 100%);
        z-index: -1;
        position: absolute;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.5s;
    }
    .product-item:hover > .product-item__body:after {
        opacity: 1;
        transition: opacity 0.2s;
    }

    .product-item:nth-child(3n+3):hover:after {
        display: none;
    }


    .product-item__body .has-img-rating > .em-holder {
        min-height: 260px;
    }
    .product-item .teaser__promotion + .teaser__promotion {
        bottom: 47px;
    }
}


@media screen and (min-width: 768px) and (max-width: 1199px) {
    /* grid-border-stuff */
    .product-item:nth-child(3n+3):after,
    .product-item:last-of-type:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100%;
        background: #e2e2e8;
    }

}


@media screen and (min-width: 992px) and (max-width: 1199px) {

    .product-item:not(:nth-child(3n+3)):hover + .product-item:before,
    .product-item:last-of-type:hover:after {
        display: none;
    }

}


@media screen and (min-width: 1200px) {

    .product-item__body {
        padding: 20px;
    }

    .product-item:not(:nth-child(4n+4)):hover + .product-item:before,
    .product-item:last-of-type:hover:after {
        display: none;
    }
    .product-item:nth-child(4n+4):hover:after {
        display: none;
    }

    .product-item__body .has-img-rating > .em-holder {
        min-height: 240px;
    }
    .product-item .teaser__promotion + .teaser__promotion {
        bottom: 53px;
    }

    /* grid-border-stuff */
    .product-item:nth-child(4n+4):after,
    .product-item:last-of-type:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        bottom: 20px;
        background: #e2e2e8;
    }

    .product-item .teaser__promotion {
        padding: 8px;
        height: 30px;
    }

    .product-item .teaser__promotion:after {
        border-width: 15px 0 15px 7px;
    }
}

@media screen and (min-width: 1300px) {
    .product-item__body .has-img-rating > .em-holder {
        min-height: 255px;
    }

}

@media screen and (min-width: 1400px) {
    .product-item__body .has-img-rating > .em-holder {
        min-height: 285px;
    }

}

@media screen and (min-width: 1470px) {
    .product-item__body .has-img-rating > .em-holder {
        min-height: 290px;
    }

    .content-slider__item .product-item__body .has-img-rating > .em-holder {
        min-height: 240px;
    }

    .content-slider__item .product-item .teaser__promotion {
        bottom: 5px;
    }

}


.product-item__teaser {
    background-color: var(--color-green);
    color: #fff;
    position: relative;
}
@media screen and (min-width: 768px) {
    .product-item__teaser {
        display: flex;
        flex-grow: 1;
    }
}
.product-item__teaser:after {
    content: '';
    position: absolute;
    right: 0;
    bottom:0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 18px 18px;
    border-color: transparent transparent var(--color-green) transparent;
}
@media screen and (min-width: 768px){
    .product-item__teaser:after {
        border-width: 0 0 33px 33px;
    }
}
@media screen and (min-width: 992px) {
    .product-item__teaser:after {
        transition: border-width 0.6s ease;
    }
    .product-item__teaser:hover:after {
        border-width: 0 0 50px 50px;
        transition: border-width 0.2s ease;
    }

}
.product-item__teaser__body {
    padding: 10px;
    font-size: 12px;
    line-height: 1.3;
}
@media screen and (min-width: 768px) {
    .product-item__teaser__body {
        padding: 15px;
        font-size: 15px;
        line-height: 1.4;
    }
}

/* page-next item*/
.product-item.product-item--next-page {
    margin-bottom: calc(20px + 25px); /* normal margin + the padding of the product-items -> so the bg is aligned with their content */
    border: 1px solid #e2e2e8;
    cursor: pointer;
}

.product-item.product-item--next-page .product-item__body:after {
    background: #f5f5fa;
}

.product-item.product-item--next-page .product-item__body:before {
    content: '';
    background-image: linear-gradient(0deg, white 0%, #f5f5fa 100%);
    z-index: -1;
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
}


.product-item--next-page:after {
    display: none;
}

.page-next__text {
    margin-top: 20px;
    color: var(--color-green);
    font-family: 'museo_500', sans-serif;
    font-size: 40px;
    line-height: 1.3;
}

@media screen and (max-width: 767px) {
    .product-item--next-page .page-next-wrapper{
        text-align: center;
        padding: 20px;
    }

    .page-next__text {
        margin-top: 0;
        font-size: 20px;
    }
}
