input.upload-btn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}
.upload-input {
    padding-right: 30px;
}
.upload-wrapper{
    position: relative;
}

.upload-image-item{
    position: relative;
    margin-bottom: 20px;
}

.delete-upload{
    cursor: pointer;
    color: #b50000;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    font-size: 10px;
    padding: 15px;
    display: none;
}