.contact {
    margin-bottom: 20px;
}
.contact a {
    color: var(--color-green);
    margin-bottom: 5px;
}
.contact a:hover {
    text-decoration: underline;
}
.contact__info {
    font-size: 12px;
}