.data-row {
    font-size: 16px;
}
@media screen and (min-width: 768px) {
    .data-row {
        padding:30px 40px 15px;
    }
}
.data-row__head {
    font-size: 13px;
    font-family:var(--font-headline-bold);
    font-weight: 400;
    margin-bottom: 15px;
}
.data-row__item {
    padding: 10px 15px;
}
@media screen and (min-width: 768px) {
    .data-row__item {
        padding: 12px 0 10px;
    }
}
.data-row__item__detail {
    font-size: 14px;
    text-decoration: underline;
}
.data-row__item__detail>.icon {
    font-size: 8px;
    margin-right: 5px;
}
.data-row__item__detail>.icon-download {
    font-size: 15px;
    vertical-align: -0.1em;
}
@media screen and (max-width: 767px) {
    .data-row__item + .data-row__item {
        border-top:1px solid #e2e2e8;
    }
    .data-row__item__main {
        font-family:var(--font-headline-bold);
        font-weight: 400;
        line-height:1.1;
    }
    .data-row__item__small{
        color: #585d5f;
        font-size: 13px;
    }
}