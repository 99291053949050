.arial-bold{
    font-family: Arial;
    font-weight: 700;
}

.checkout-main {
    margin-left: 0;
}

.checkout-heading{
    position: absolute;
    top: 50%;
}

.guest-login{
    position: absolute;
    bottom: 30px;
    right: 30px;
}

.checkout-objects{
    border: 1px solid var(--color-light-grey);
    height: 100%;
    position: relative;
}

.checkout--edit-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: var(--color-green);
    padding: 20px;
}

.toggle-panel>a.collapsed .toggle-panel-span{
    transform: rotate(0deg);
}

.toggle-panel>a .toggle-panel-span{
    transform: rotate(180deg);
}

.form-control--info{
    width: 30%;
    margin-left: 10px;
    display: inline-block;
}
.item__inner .item__body .checkout--edit-btn > .icon {
    margin-right: 5px;
}

/* Header */
.navbar-brand-checkout {
    padding: 0;
    width: 240px;
    height: 137px;
    min-width: auto;
    margin-bottom: 15px;
}
@media screen and (min-width: 768px){
    .checkout-hl {
        margin-bottom: 0;
        padding-left: 45px;
        line-height:calc(72/60);
    }
    .container-checkout {
        padding-bottom: 120px;
    }
    .navbar-brand-checkout>img {
        max-width: 168px !important;
    }
    .mt-20-sm{
        margin-top: 20px;
    }
}
.container-checkout {
    margin-top: 20px;
    padding-bottom: 40px;
}

/* accordion */
.panel-title .icon-circle {
    color: var(--color-green);
    margin-right: 8px;
    position: relative;
    top:2px;
}

.panel-title .collapsed .icon-circle {
    color: #000;

}

/* XS */
@media screen and (max-width: 767px) {
    #header-checkout {
        background-color: var(--color-green);
    }
    .navbar-brand-checkout {
        background: none;
        padding: 10px;
        width: 110px;
        height: auto;
        float: none;
        display: block;
        margin: 0 auto;
    }
    .checkout-hl {
        font-size: 18px;
        color: #fff;
    }
    .container-checkout {
        margin-top: 0px;
    }

    /* checkout main-col */
    .checkout-main {
        margin-bottom: 15px;
    }

    .sidebar-item--first{
        padding: 20px 20px 0;
    }

    .sidebar-item{
        padding: 10px 20px;
    }
}


/* CHECKOUT FORMS
 ---------------- */
.checkout-main .content-formular {
    background: #fff;
}

/* CHECKOUT SIDEBAR
 ----------------- */

.form-separator{
    border-top: 1px solid #bababa;
    padding-top: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.bg-light-grey{
    background: var(--color-bg-primary);
}

.reservation-separator{
    border-bottom: 2px solid white;
}

.sidebar-item--first{
    padding: 30px 30px 0;
}

.sidebar-item{
    padding: 15px 30px;
}

.checkout_list-products .checkout_list-products__item {
    padding-top:5px;
    font-size: 85%;
}
.checkout_list-products .checkout_list-products__item + .checkout_list-products__item {
}

.checkout_list-products .has-endprice {
    border-top: 2px solid var(--color-green);
    padding-top: 5px;
    margin-top: 10px;
}

.checkout-main__intro {
    margin-bottom: 30px;
    font-size: 14px;
    line-height:1.56;
}
@media screen and (min-width: 768px) {
    .checkout-main__intro {
        margin-bottom: 44px;
        font-size: 16px;
    }
}
.checkout-main__form h3 {
    margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
    .checkout-main__form label {
        font-size: 14px;
    }
}
.checkout-main__form .checkbox {
    margin-top: 15px;
}
.checkout-main__form:not(.checkout-main__form--fuel-shop) .checkbox>label, .checkout-main__form:not(.checkout-main__form--fuel-shop) .radio>label {
    font-size: 13px;
}
@media screen and (min-width: 768px) {
    .checkout-main__form .checkbox {
        margin-top: 30px;
    }
    .checkout-main__form:not(.checkout-main__form--fuel-shop) .checkbox>label, .checkout-main__form:not(.checkout-main__form--fuel-shop) .radio>label {
        font-size: 18px;
    }
}
.checkout-main__form hr {
    border-top: 2px solid #d0d0da;
    margin-top: 10px;
    margin-bottom: 30px;
}
.checkout-main__form .radio {
    margin-top: 15px;
    margin-bottom: 25px;
}
.checkout-main__form .form-group .radio:first-of-type {
    margin-top: 15px;
}
.checkout-main__form .panel-group {
    margin-left: -15px;
    margin-right: -15px;
}
@media screen and (min-width: 768px) {
    .checkout-main__form .panel-group {
        margin-left: -20px;
        margin-right: -20px;
    }
}
.checkout-main__form + .checkout-main__form {
    margin-top: 2px;
}
.checkout-main__form button[type=submit] {
    white-space: normal;
}

/*section*/
.checkout-section {
    padding: 20px;
}
.checkout-section__title {
    color: var(--color-green);
    font-size: 20px;
    line-height: calc(24/20);
}
@media screen and (min-width: 768px) {
    .checkout-section__title {
        font-size: 24px;
    }
}

.register-success {
    font-size: 20px;
    font-weight: bold;
    color: var(--color-green);
}

/* hobex button*/
.wpwl-form-virtualAccount-PAYPAL .wpwl-group {
    text-align: center;
}