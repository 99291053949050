.markenshop .container {
    padding: 0;
}

#main.markenshop {
    padding-left: 0;
}

.markenshop h1 {
    color: #2B3133;
}

.markenshop__teaser {
    margin-bottom: 50px;
    line-height: 28px;
}

.markenshop__teaser, .markenshop__filter {
    font-size: 18px;
}

.markenshop__teaser a, .teaser-box__text a, .content-box__text a {
    text-decoration: underline
}

.markenshop__filter__title {
    font-family: var(--font-headline-default);
    color: #585D5F;
    float: left;
    margin-right: 20px;
}

.markenshop__filter ol {
    padding: 0;
}

.markenshop__filter ol li {
    display: inline-block;
    padding: 0 5px;
    border-right: 1px solid #D0D0DA;
    color: #9C9FA3;
}

.markenshop__filter ol li:first-child {
    border-left: 1px solid #D0D0DA;
}

.markenshop__filter ol li a.active {
    color: #118516;
    font-weight: bold;
}

.content-section.markenshop__grid {
    margin: 40px 0 80px;
}

.markenshop__grid__element {
    position: relative;
    background: #F5F5FA;
}

.markenshop__grid__element .letter {
    font-size: 18px;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;
    padding: 9px 12px;
    font-weight: bold;
    background-color: #fff;
    z-index: 5;
}

.markenshop__grid__element img.logo {
    position: absolute;
    margin: 0 auto;
    max-width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.markenshop__grid__element:hover {
    border: 1px solid #118516;
}

.markenshop__grid__element:hover:after, .teaser-box__text:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 35px 35px;
    border-color: transparent transparent var(--color-green);
}



.markenshop__grid__element:hover img {
    transform: scale(1.05) translate(-50%, -50%);
    transform-origin: top left;
    transition: transform 300ms;
}


.markenshop--detail__header {
    position: relative;
    background: radial-gradient(circle, #169E29 0%, #118516 100%);
    color: #fff;
    padding: 60px 0;
    margin-top: -15px;
    margin-bottom: 80px;
}

.markenshop--detail__header h1 {
    color: #fff;
}

.markenshop--detail__header img.logo {
    width: 50%;
}

.markenshop--detail__header:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    bottom: -33px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 40px 35px 40px;
    border-color: transparent transparent #159925;
}

@media screen and (max-width: 767px) {
    .markenshop__filter__title {
        float: none;
        margin-bottom: 10px;
    }

    .markenshop--detail__header {
        padding: 30px 15px 0;
        margin: 2px 0 0;
        box-shadow: 0 0 15px #fff;
    }

    .markenshop--detail__header:after {
        display: none;
    }

    .markenshop--detail__header__logo {
        display: none;
    }

    .markenshop__logo-mobile {
        margin-bottom: 20px;
        height: 90px;
        width: auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .markenshop--detail__header {
        padding: 30px 15px 0 15px;
    }

    .markenshop--detail__header__logo img.logo {
        width: 80%;
    }
}

.teaser-box, .content-box {
    margin: 50px 0;
}

.teaser-box__img {
    position: relative;
}

.teaser-box__img__alt {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #FFF028;
    color: #2B3133;
    font-family: var(--font-headline-bold);
    font-size: 15px;
    line-height: 18px;
    padding: 10px 20px;
    max-width: 190px;
}

.teaser-box__text {
    background: radial-gradient(circle, #169E29 0%, #118516 100%);
    padding: 20px;
    color: #fff;
    position: relative;
    font-size: 14px;
    line-height: 22px;
    min-height: 200px;
}

.teaser-box__text h2 {
    font-size: 26px;
    color: #fff;
}

.content-box__img {
    margin-bottom: 20px;
}

.content-box h2 {
    font-size: 20px;
    color: #2B3133;
    margin: 10px 0;
}

.content-box__text {
    font-size: 14px;
    line-height: 22px;
}

.content-box__text a.btn {
    text-decoration: none;
    margin-top: 5px;
}

.markenshop--detail__filter .filter-box {
    margin-bottom: 0;
}