.cc-steps__item {
    display: inline-block;
    margin-right: 10px;
    text-align: center;;
    width: 90px;
    vertical-align: top;
}

.cc-steps__item:last-child {
    margin-right: 0;
}

.cc-steps__text {
    position: relative;
    font-family: 'interstate_regular', serif;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 1.2;
    padding: 0 5px;
}
.cc-steps__item:not(:last-child) .cc-steps__text:after {
    content: "\E004";
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: -7px;
    transform: translateY(-50%);
    font-size: 30px;
    color: #d9d9e1;
}

.cc-steps__text__number {
    font-size: 16px;
}

.cc-steps__icon-wrapper {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 0 auto 15px;
}

.cc-steps__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 55px;
    transform: translateX(-50%) translateY(-50%);
    color: #d0d0da;
}
