@media screen and (min-width: 768px) {
    .visible-affix.visible-affix {
        display: none;
    }
    .affix .visible-affix {
        display: inline-block;
    }
    .affix-header {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1000;
    }
    .affix-header.affix {
        position: fixed;
        height: 80px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        background: #fff;
    }
    .affix .article-header--meta,
    .affix .navbar-form,
    .affix .navbar-brand .brand-slogan,
    .affix .arrange--header-wrapper__logo .dropdown-toggle,
    .affix .hidden-affix {
        display: none;
    }
    .affix .navbar-brand {
        height: 80px;
        padding: 12px 10px;
        min-width: auto;
    }
    .affix .navbar-brand>img {
        max-height: 54px;
    }
    .affix #nav-main {
        font-size: 16px;
    }
    .affix .navbar-main {
        float: left;
        margin-top: 12px;
    }
    .affix .article-header--search-user {
        float: right;
        margin-top: 28px;
    }
    .affix #nav-main>ul>li>a {
        padding: 13px 8px 30px;
        font-size: 14px;
        height: 58px;
    }
    .affix .navbar-main #btn-dropdownOnlineShop {
        padding: 8px 8px 25px;
        font-size: 14px;
    }
    .affix.affix-header {
        transform: translateZ(0);
    }
    .affix #nav--user-console>ul>li .user-console__text {
        padding: 0;
    }
}
@media screen and (min-width: 1400px) {
    .affix #nav-main>ul>li>a {
        font-size: 17px;
    }
    .affix .navbar-main #btn-dropdownOnlineShop {
        font-size: 17px;
    }
}
@media screen and (min-width: 1300px) {
    .affix #nav-main>ul>li>a {
        padding: 12px 16px 17px;
    }
}