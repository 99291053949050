/* grid */

    .row--has-product-items {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
    }
    .row--has-product-items:before,
    .row--has-product-items:after {
        display: none;
    }
    .row--has-product-items > .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }



@media screen and (max-width: 767px) {

    .product-grid {
        margin-left: -10px;
        margin-right: -10px;
    }
    .row--has-product-items .product-item {
        padding: 15px 0;
        background-image: linear-gradient(to top, white 0%, #f5f5fa 100%);
        margin-top: -1px;
        border-top: 1px solid #e2e2e8;
        border-bottom: 1px solid #e2e2e8;
    }
    .row--has-product-items .product-item:nth-child(2n-1) {
        border-right: 1px solid #e2e2e8;
    }
    .row--has-product-items > .product-item > .product-item__body {
        padding: 0 15px;
    }

    .product-grid .has-prod-info {
        margin-top: 10px;
    }
    .product-grid .product-item__body {
        display: flex;
        flex-grow: 1;
    }
    .product-grid .product-item__body > .row {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        /* make this flexbox markup work in ie11*/
        min-width: 1px;
        ms-flex: 1 0 0px;
    }
    .product-grid .product-item__body .has-prod-info {
        display: flex;
        flex-direction:column;
        flex-grow: 1;
        justify-content: space-between;
    }
}


/* Loading Animation */
@media screen and (max-width: 767px){
    .has-loading-animation {
        filter: opacity(.4);
    }
}
@media screen and (min-width: 768px){
    .has-loading-animation {
        filter:blur(2px) grayscale(80%);
        transition: all 0.2s;
    }
}


/* Content Item todo*/
@media screen and (min-width: 768px){
    .product-grid--teaser-picture {
        width: 100%;
        height: 400px;
    }
}
@media screen and (max-width: 767px) {
    .product-grid--teaser-picture {
        height: 100%;
        width: 100%;
    }
}
