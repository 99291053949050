.form-block {
    padding: 25px 20px;
    margin-bottom: 10px;
}
@media screen and (min-width: 768px) {
    .form-block {
        padding: 28px 40px;
    }
}
.form-block__hl {
    font-family:var(--font-headline-bold);
    font-size: 20px;
    margin-bottom: 10px;
}
.form-block .form-group {
    margin-bottom: 15px;
}
.form-block label {
    font-size: 13px;
}