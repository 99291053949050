@media screen and (max-width: 767px) {
    #header-main form.mobile-search-form {
        position: absolute;
        right:55px;
        top:-3px;
        width:160px;
        z-index: 50;
    }

    #header-main form.mobile-search-form .form-control{
        box-shadow: none;
        padding: 6px 10px;
    }

    #btn-showMobileNav {
        border-left: 0;
    }
}

@media screen and (min-width:375px) and (max-width: 767px) {
    .arrange--header-wrapper__logo {
        width: 30%;
    }
    #header-main form.mobile-search-form {
        width:220px;
    }
}