.suppliers-user {
    height: 49px;
    white-space: nowrap;
    vertical-align: bottom;
    display: table-cell;
    padding-bottom: 10px;
}

.table-history td {
    white-space: nowrap;
}

.history-popover-content {
    display: none;
}

.dz-remove {
    top: 8px;
    right: 8px;
    position: absolute;
    z-index: 500;

    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
    color: red;
}
.dz-remove:before {
    content: "\E00C";
}
.dz-preview {
    box-shadow: 0 0 5px 0 rgba(0,0,0,.5);
}
.dz-image {
    border-radius: 0 !important;
}
.dz-picture-checkbox {
    top: -5px;
    left: 5px;
    position: absolute;
    z-index: 500;
}

.dropzone.dz-started .dz-message {
    /*display: block !important;*/
}
.popover {
    min-height: 500px;
    width: 600px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.5);
    background-color: white;
    position: absolute;
    padding: 10px;
    top: 0 !important;
    right: 80px !important;
    left: auto !important;
    z-index: 99999;
}

.popover.left>.arrow {
    top: 30px !important;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0,0,0,.25);
}

.popover>.arrow {
    border-width: 11px;
}
.popover>.arrow, .popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.popover-content {
    padding-top: 15px;
}
.history-text-old {
    color: #d0d0da;
}

.history-text-new {
    border-right: 1px solid #eee;
}

.history-text-value {
    word-wrap:break-word;
    white-space: normal;
}

.dropzone.history-text-value {
    padding: 0;
}

.compare-arrow-wrap {


}

.compare-arrow {
    align-items: center; /* Vertical center alignment */
    justify-content: center; /* Horizontal center alignment */
    font-size: xx-large;
}

.compare-arrow > .icon {
    cursor: pointer;
}

.new-history-row {
    border-left: 2px solid #118516;
}

.value-compare-row .dropzone {
    min-height: 0;
}


.clickableCheckbox {
    cursor: pointer;
}

.suppliers-body .nav-category li.all-categories>a {
    margin-bottom: 11px;
}


.dz-hovered img {
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px);
}

.dropzone .dz-preview.dz-hovered .dz-details {
    opacity: 1;
}

table>thead>tr>th.supplier-table-list-header {
    vertical-align: middle;
    text-align:center;
    padding-left: 5px;
    padding-right: 5px;
}

#nav-main.nav-supplier {
    font-size: 20px;
}

.fake-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.fake-label .sublabel, label .sublabel {
    font-weight: normal;
    font-size: 12px;
}

#sticky.stick {
    margin-top: 40px !important;
    position: fixed;
    top: 0;
    z-index: 10000;
}

.table-history tr.marked {
    background-color: #C7DFCC !important;
}

.history-dropdown-icon {
    color: #118516;
    margin-right: 8px;
    position: relative;
    top: 2px;
}
.history-dropdown-icon:before {
    content: "\E005";
    font-family: iconfont;
}


.collapsed > .history-dropdown-icon {
    color: #000;
}
.collapsed > .history-dropdown-icon:before {
    content: "\E001";
    font-family: iconfont;
}
#nav-main>ul>li.nav-splitter>a:after {
    background-color: #118516;
    width: 5px;
}

.selectedBox {
    border: 4px solid #118516;
}

.supplier-alert {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    padding: 10px;
}